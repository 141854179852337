import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const alertService = createApi({
  reducerPath: 'alert',
  tagTypes: 'alerts',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://welcome-habibi-backend.vercel.app/v1/'
  }),
  endpoints: (builder) => {
    return {
      create: builder.mutation({
        query: (name) => {
          return {
            url: 'manager/create-alert',
            method: 'POST',
            body: name
          };
        }
      }),
      updatealert: builder.mutation({
        query: (data) => {
          return {
            url: `manager/edit-alert/${data.id}`,
            method: 'PATCH',
            body: { name: data.name }
          };
        }
      }),
      deletealert: builder.mutation({
        query: (id) => {
          return {
            url: `manager/delete-alert/${id}`,
            method: 'DELETE'
          };
        }
      }),

      fetchalert: builder.query({
        query: (id) => {
          return {
            url: `worker/all-alert/${id}`,
            method: 'GET'
          };
        }
      }),
      allalerts: builder.query({
        query: () => {
          return {
            url: `manager/get-all-alert`,
            method: 'GET'
          };
        }
      })
    };
  }
});
export const {
  useCreateMutation,
  useFetchalertQuery,
  useAllalertsQuery,
  useUpdatealertMutation,
  useDeletealertMutation
} = alertService;
export default alertService;
