import './App.css';
import Routing from './routes/Routing';
function App() {
  return (
    <>
      <Routing />

      {/* <h1 className=" text-center pt-5 text-3xl">Optimizes the application for the best runtime performance</h1> */}
    </>
  );
}

export default App;
