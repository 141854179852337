import { Link, useNavigate } from 'react-router-dom';
import Footer from '../../components/footer';
import Wrapper from '../../layout/Wrapper';
import { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { MdClose } from 'react-icons/md';
import Modal from '../../components/modal';
import Input from '../../components/Input';
import { toast } from 'react-toastify';
import Button from '../../components/Button';
import { useForm } from '../../hooks/Form';
import { useAuthLoginMutation } from '../../store/services/authService';
import {
  setAdminToken,
  setInvestorToken,
  setManagerToken,
  setWorkerToken
} from '../../store/reducers/authReducer';
import { setSuccess } from '../../store/reducers/globalReducer';

const AdminLogin = () => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const cnicRegex = /^\d{13}$/;
  const [loading, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { state, onChange } = useForm({
    identifier: '',
    password: ''
  });

  const [loginUser, response] = useAuthLoginMutation();
  // console.log(response);

  const onSubmit = (e) => {
    e.preventDefault();

    if (state.identifier === 'amirshahzad629220@gmail.com') {
      setIsModalOpen(true);
    } else {
      let hasError = false;

      if (!state.identifier) {
        toast.error('Must enter email');
      } else if (!emailRegex.test(state.identifier) && !cnicRegex.test(state.identifier)) {
        toast.error('Must enter a valid email address or CNIC number');
        hasError = true;
      } else if (!state.password) {
        toast.error('Must enter password');
        hasError = true;
      } else {
        setLoader(true);
        loginUser(state);
      }
    }
  };

  const AdminEmailModal = ({ isOpen, closeModal }) => {
    const [password, setPassword] = useState(''); // State for password entered in the modal
    const codeInputRef = useRef(null); // Create a ref for the input field

    // Focus the input field automatically when the modal opens
    useEffect(() => {
      if (isOpen && codeInputRef.current) {
        codeInputRef.current.focus(); // Set focus on the code input field
      }
    }, [isOpen]);

    const onCodeSubmit = async (e) => {
      e.preventDefault();
      setLoader(true);
      if (!password) {
        toast.error('Must Enter Your Code');
        setLoader(false);
        return;
      }
      // console.log(password);
      // console.log(state.identifier);
      // console.log(state.password);
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      const raw = JSON.stringify({
        otp: password
      });

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch('https://google-athenticator.vercel.app/verify', requestOptions)
        .then((response) => response.text())
        .then((result) => {
          let data = JSON.parse(result);
          console.log('---->>>', data);
          if (data?.success === false) {
            setLoader(false);
            toast.error(data?.error);
          } else {
            let hasError = false;

            if (!state.identifier) {
              toast.error('Must enter email');
            } else if (!emailRegex.test(state.identifier) && !cnicRegex.test(state.identifier)) {
              toast.error('Must enter a valid email address or CNIC number');
              hasError = true;
            } else if (!state.password) {
              toast.error('Must enter password');
              hasError = true;
            } else {
              loginUser(state);
              setLoader(false);
            }
          }
        })
        .catch((error) => console.error(error));
    };

    return (
      isOpen && (
        <div>
          <Modal isOpen={isOpen} className={'md:w-[50%] w-full'} onClose={closeModal}>
            <div className="p-5">
              <div className="flex justify-between items-center">
                <p>Enter Code</p>
                <MdClose className="cursor-pointer text-gray-500" onClick={closeModal} size={25} />
              </div>
              <div className="flex gap-3 p-5 flex-wrap">
                <div className="w-full">
                  <Input
                    label={'6 Digit Code'}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    name={'code'}
                    type="number"
                    className={'w-full py-4'}
                    ref={codeInputRef} // Attach the ref here
                  />
                </div>
                <div className="w-full mt-3">
                  {loading === true ? (
                    <button
                      disabled
                      type="button"
                      className="bg-blue text-xl text-center w-full py-3 rounded text-white font-semibold cursor-pointer"
                    >
                      <svg
                        aria-hidden="true"
                        role="status"
                        className="inline w-4 h-4 me-3 text-white animate-spin"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.992 72.5987 9.67208 50 9.67208C27.4013 9.67208 9.08144 27.992 9.08144 50.5908Z"
                          fill="#E5E5E5"
                        />
                        <path
                          d="M39.3493 29.6267C42.8295 24.4044 47.7795 20.6655 53.5469 18.3292C59.3143 15.9929 65.6441 15.1758 71.6398 15.0332C77.6354 14.8907 83.4973 16.4251 88.6257 19.4419C93.7541 22.4587 97.9197 26.7237 101.062 31.9069C104.204 37.09 106.212 43.0907 106.024 49.2759C105.836 55.461 103.456 61.2447 99.3014 66.4302C95.1469 71.6157 89.4031 75.6979 83.0904 78.3106C76.7778 80.9232 70.0632 82.0117 63.2792 81.3277C56.4952 80.6437 49.9023 79.2294 43.6519 77.2353C37.4015 75.2413 31.6599 72.7119 26.4786 69.8334L39.3493 29.6267ZM48.4521 44.1173C44.9904 46.7048 41.5683 49.3227 38.1909 52.0971C36.0819 53.9541 34.2199 56.0087 32.7665 58.1437C32.4748 58.7888 32.2309 59.5134 32.0417 60.2823C31.8525 61.0513 31.7312 61.8561 31.6817 62.6704C31.6322 63.4847 31.6552 64.3032 31.7482 65.1049C31.8412 65.9066 32.0016 66.6875 32.2223 67.4263C32.443 68.1651 32.7191 68.8596 33.0469 69.4966L48.4521 44.1173Z"
                          fill="currentColor"
                        />
                      </svg>
                      Logging In...
                    </button>
                  ) : (
                    <Button
                      onClick={onCodeSubmit}
                      label={'Submit'}
                      className={
                        'bg-blue text-center justify-center uppercase text-white py-3 border-b-black border-2 w-full'
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </Modal>
        </div>
      )
    );
  };

  useEffect(() => {
    if (response.isError) {
      setErrors(response?.error?.data?.message);
      toast.error(response?.error?.data?.message);
      setLoader(false);
    }
  }, [response?.error?.data]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (response.isSuccess) {
      if (response?.data?.success === true) {
        setLoader(false);
        if (response?.data?.user?.role === 'admin') {
          dispatch(setAdminToken(response?.data?.user?._id));
          navigate('/super_admin/dashboard');
          toast.success(response?.data?.message);
          dispatch(setSuccess(response?.data?.msg));
        } else if (response?.data?.user?.role === 'manager') {
          dispatch(setManagerToken(response?.data?.user?._id));
          localStorage.setItem('manager_data', JSON.stringify(response?.data?.user));
          navigate('/manager/dashboard');
          toast.success(response?.data?.message);
          dispatch(setSuccess(response?.data?.msg));
        } else if (response?.data?.user?.role === 'worker') {
          dispatch(setWorkerToken(response?.data?.user?._id));
          localStorage.setItem('worker_data', JSON.stringify(response?.data?.user));
          navigate('/workers/my_portal');
          toast.success(response?.data?.message);
          dispatch(setSuccess(response?.data?.msg));
        } else if (response?.data?.model === 'Investor') {
          dispatch(setInvestorToken(response?.data?.user?._id));
          navigate('/investor/plans');
          toast.success(response?.data?.message);
          dispatch(setSuccess(response?.data?.msg));
          localStorage.setItem('investor_data', JSON.stringify(response?.data?.user));
        } else {
        }
      } else {
        toast.error(response?.data?.message);
        setLoader(false);
      }
    }
  }, [response.isSuccess]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Wrapper
        className="h-screen flex justify-center items-center"
        ToggleBtn={
          <div className="md:hidden block">
            <img src={require('../../assets/image/menu.png')} alt="" />
          </div>
        }
        title_props={
          <>
            <h1 className="main_title">Welcome Habibi Trading</h1>
          </>
        }
      >
        <form onSubmit={onSubmit} className="bg-white shadow-2xl md:flex block w-full rounded">
          <div className="text-center md:w-[50%] w-full">
            <img
              src={require('../../assets/image/login_bg.png')}
              className="h-full object-cover"
              alt=""
            />
          </div>

          <AdminEmailModal isOpen={isModalOpen} closeModal={closeModal} />

          <div className="bg-BG md:px-14 px-6 pt-2 bg-cover bg-center bg-[#0054A5] flex items-center justify-center md:w-[50%] w-full">
            <div>
              <h1 className="text-center font-semibold text-3xl text-white pb-8">Login</h1>
              <div className="mb-4 mt-4">
                <label className="text-white font-normal py-3">Email Address/CNIC Number</label>
                <input
                  onChange={onChange}
                  type="text"
                  value={state.identifier}
                  name="identifier"
                  className="w-full bg-white mt-2 p-4 rounded outline-none text-black"
                  placeholder="Enter email..."
                  autoComplete="off"
                />
              </div>
              <div className="mb-4">
                <label className="text-white font-normal py-3">Password</label>
                <input
                  onChange={onChange}
                  type="password"
                  value={state.password}
                  name="password"
                  className="w-full mt-2 bg-white p-4 rounded outline-none text-black"
                  placeholder="Enter password..."
                  autoComplete="off"
                />
              </div>

              <div className="mb-4">
                {loading === true ? (
                  <button
                    disabled
                    type="button"
                    className="bg-blue text-xl text-center w-full py-3 rounded text-white font-semibold cursor-pointer"
                  >
                    <svg
                      aria-hidden="true"
                      role="status"
                      className="inline w-4 h-4 me-3 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.992 72.5987 9.67208 50 9.67208C27.4013 9.67208 9.08144 27.992 9.08144 50.5908Z"
                        fill="#E5E5E5"
                      />
                      <path
                        d="M39.3493 29.6267C42.8295 24.4044 47.7795 20.6655 53.5469 18.3292C59.3143 15.9929 65.6441 15.1758 71.6398 15.0332C77.6354 14.8907 83.4973 16.4251 88.6257 19.4419C93.7541 22.4587 97.9197 26.7237 101.062 31.9069C104.204 37.09 106.212 43.0907 106.024 49.2759C105.836 55.461 103.456 61.2447 99.3014 66.4302C95.1469 71.6157 89.4031 75.6979 83.0904 78.3106C76.7778 80.9232 70.0632 82.0117 63.2792 81.3277C56.4952 80.6437 49.9023 79.2294 43.6519 77.2353C37.4015 75.2413 31.6599 72.7119 26.4786 69.8334L39.3493 29.6267ZM48.4521 44.1173C44.9904 46.7048 41.5683 49.3227 38.1909 52.0971C36.0819 53.9541 34.2199 56.0087 32.7665 58.1437C32.4748 58.7888 32.2309 59.5134 32.0417 60.2823C31.8525 61.0513 31.7312 61.8561 31.6817 62.6704C31.6322 63.4847 31.6552 64.3032 31.7482 65.1049C31.8412 65.9066 32.0016 66.6875 32.2223 67.4263C32.443 68.1651 32.7191 68.8596 33.0469 69.4966L48.4521 44.1173Z"
                        fill="currentColor"
                      />
                    </svg>
                    Logging In...
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="bg-blue text-xl text-center w-full py-3 rounded text-white font-semibold cursor-pointer"
                  >
                    Login
                  </button>
                )}
              </div>
            </div>
          </div>
        </form>
      </Wrapper>
      <Footer />
    </>
  );
};

export default AdminLogin;
