import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Base_url } from '../../../utils/Base_url';
import { VscFilter } from 'react-icons/vsc';
import { GoTriangleDown } from 'react-icons/go';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import Add_Rewards from './AddRewards';
import Manager_web_portal from '../../../layout/Manager_web_portal';
import { IoSearchOutline } from 'react-icons/io5';
import { HiOutlineArrowLeft } from 'react-icons/hi';
import { useOverallrewardsQuery } from '../../../store/services/rewardService';
import { toast } from 'react-toastify';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import moment from 'moment';
import Super_admin_web_protal from '../../../layout/Super_admin_web_portal';
import Swal from 'sweetalert2';
import { RiDeleteBin5Line } from 'react-icons/ri';
const Rewards = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(null);
  useEffect(() => {
    axios
      .get(`${Base_url}/customer/getAll`)
      .then((res) => {
        // console.log(res);

        setUsers(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const { data, isFetching } = useOverallrewardsQuery();
  const [searchTerm, setSearchTerm] = useState('');
  const [minValue, setMinValue] = useState('');
  const [maxValue, setMaxValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [statusFilter, setStatusFilter] = useState('');
  const itemsPerPage = 6;
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filteredDeposits, setFilteredDeposits] = useState([]);
  useEffect(() => {
    filterDeposits();
  }, [data, searchTerm, minValue, maxValue, statusFilter]);

  const toggleDropdown = () => setDropdownVisible(!dropdownVisible);

  const filterDeposits = () => {
    let filtered = data?.rewards?.slice()?.reverse() || [];
    if (searchTerm) {
      filtered = filtered.filter(
        (deposit) =>
          deposit.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          deposit.cnic.toLowerCase().includes(searchTerm.toLowerCase()) ||
          deposit.investment.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    if (minValue || maxValue) {
      const min = parseFloat(minValue) || Number.MIN_SAFE_INTEGER;
      const max = parseFloat(maxValue) || Number.MAX_SAFE_INTEGER;
      filtered = filtered.filter((deposit) => {
        const amount = parseFloat(deposit.amount);
        return amount >= min && amount <= max;
      });
    }

    if (statusFilter) {
      setDropdownVisible(false);
      filtered = filtered.filter(
        (deposit) => deposit.status2.toLowerCase() === statusFilter.toLowerCase()
      );
    }

    setFilteredDeposits(filtered);
    setCurrentPage(1);
  };

  const currentPageData = filteredDeposits.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (newPage) => setCurrentPage(newPage);

  const update_Rewards = (id, invesId) => {
    // console.log(id);
    // console.log(invesId);
    setLoading(id);
    axios
      .get(`https://welcome-habibi-backend.vercel.app/v1/worker/single-investor/${invesId}`)
      .then((response) => {
        console.log('--->> Reward of Investor', response);

        const params = {
          rewardId: id,
          status2: 'paid'
        };
        console.log('-->> Rewards ID', id);

        axios
          .post(`${Base_url}/v1/manager/update-reward-status`, params)
          .then((res) => {
            // console.log(res);
            if (res?.data?.success === true) {
              toast.success(res?.data?.message);
              window.location.reload();
              console.log('Updated Responce', res);

              const params = {
                workerId: response?.data?.investment?.createdBy?._id,
                depositAmount: response?.data?.investment?.amount,
                plan: response?.data?.investment?.planId?._id
              };
              axios
                .post(`${Base_url}/v1/manager/approve-reward-email`, params)
                .then((res) => {
                  // console.log(res);

                  if (res?.data?.success === true) {
                    setLoading(null);
                    window.location.reload();
                  }
                })
                .catch((error) => {});
            } else {
              toast.success(res?.data?.message);
            }
          })
          .catch((error) => {});
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const removeFunction = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#A47ABF',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(`${Base_url}/v1/admin/delete-reward/${id}`)
          .then((res) => {
            // console.log(res);
            if (res.status === 200) {
              Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
              window.location.reload();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  return (
    <Super_admin_web_protal
      language={
        <>
          <p className=" sm:block hidden">English | Urdu</p>
        </>
      }
      ToggleBtn={
        <div className=" flex gap-4 items-center">
          <HiOutlineArrowLeft />
          <h2 className="">Rewards</h2>
        </div>
      }
      title_props={
        <>
          <div className=" w-96 relative">
            <Input
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder={''}
              className={' border bg-white w-full rounded-full'}
            />

            <Button
              Icon={<IoSearchOutline className=" text-white text-xl" />}
              label={'search'}
              className={
                ' absolute top-0 right-0 bg-secondary  text-white uppercase font-normal rounded-full'
              }
            />
          </div>
        </>
      }>
      <Add_Rewards isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} setUsers={setUsers} />
      <div className="  w-full  mb-4  md:hidden block relative">
        <Input
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder={''}
          className={' border bg-white w-full rounded-full'}
        />

        <Button
          Icon={<IoSearchOutline className=" text-white text-xl" />}
          label={'search'}
          className={
            ' absolute top-0 right-0 bg-secondary text-white uppercase font-normal rounded-full'
          }
        />
      </div>
      <div className=" sm:flex block  relative   justify-between items-center">
        <div>
          <h2 className=" sm:block hidden">Rewards</h2>
        </div>

        <div className=" flex gap-3 items-center">
          <div className=" ">
            <Button
              Icon={<VscFilter size={27} />}
              Icons={<GoTriangleDown />}
              label={'Select Filters'}
              onClick={toggleDropdown}
              className={' bg-white font-normal text-darkGray py-3 rounded-full'}
            />
            {dropdownVisible && (
              <div className="absolute z-10 p-3 mt-2 w-96 rounded-md shadow-lg bg-white right-0 ring-black ring-opacity-5">
                <div className="py-1 gap-4 flex">
                  <div className=" py-2">
                    <label htmlFor="minValue" className="block text-sm font-medium text-gray-700">
                      Min Value
                    </label>
                    <Input
                      type="number"
                      id="minValue"
                      value={minValue}
                      onChange={(e) => setMinValue(e.target.value)}
                      className="mt-1 p-2 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                  <div className=" py-2">
                    <label htmlFor="maxValue" className="block text-sm font-medium text-gray-700">
                      Max Value
                    </label>
                    <Input
                      type="number"
                      id="maxValue"
                      value={maxValue}
                      onChange={(e) => setMaxValue(e.target.value)}
                      className="mt-1 p-2 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                  <div className="py-2">
                    <label htmlFor="maxValue" className="block text-sm font-medium text-gray-700">
                      Status
                    </label>
                    <select
                      id="status"
                      className="mt-2.5 p-2 bg-[#F6F6F6] rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      onChange={(e) => setStatusFilter(e.target.value)}
                      value={statusFilter}>
                      <option value="">All</option>
                      <option value="paid">Paid</option>
                      <option value="unpaid">Unpaid</option>
                    </select>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <section className="mb-20 mt-7 text-gray-800">
        <div className="block rounded-lg ">
          <div className="flex overflow-x-auto flex-col">
            <div className=" sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full sm:px-6 lg:px-8">
                <div className="overflow-hidden">
                  <table className="min-w-full mb-0">
                    <thead className=" ">
                      <tr className=" rounded-lg whitespace-nowrap ">
                        <th scope="col" className=" text-sm  text-darkGray font-semibold px-6 py-4">
                          Reward Date
                        </th>
                        <th scope="col" className=" text-sm  text-darkGray font-semibold px-6 py-4">
                          Investment ID
                        </th>
                        <th
                          scope="col"
                          className=" text-sm text-darkGray    font-semibold px-6 py-4">
                          Name
                        </th>
                        <th
                          scope="col"
                          className=" text-sm text-darkGray   font-semibold px-6 py-4">
                          CNIC No
                        </th>

                        {/* <th
                          scope="col"
                          className="text-sm  text-darkGray   font-semibold px-6 py-4"
                        >
                         Remarks
                        </th> */}

                        <th
                          scope="col"
                          className="text-sm  text-darkGray     font-semibold px-6 py-4">
                          Deposit Amount
                        </th>
                        <th
                          scope="col"
                          className="text-sm  text-darkGray     font-semibold px-6 py-4">
                          Reward Amount
                        </th>
                        <th
                          scope="col"
                          className="text-sm    text-darkGray   font-semibold px-6 py-4">
                          Status
                        </th>

                        <th
                          scope="col"
                          className="text-sm   text-darkGray   font-semibold px-6 py-4"></th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {currentPageData?.map((item, index) => {
                        // if (item?.status === "approved") {
                        return (
                          <>
                            <tr className="bg-white border-t-8 border-b-8 border-lightGray  rounded-md ">
                              <th
                                scope="row"
                                className="text-sm font-normal px-6 py-4   whitespace-nowrap ">
                                <p className="mb-0.5 font-medium text-black">
                                  {moment(item?.createdAt).format('DD/MM/YYYY')}
                                </p>
                              </th>
                              <td className="align-middle text-sm font-normal px-6 py-4 whitespace-nowrap  text-center">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                  {item?.investment}
                                </span>
                              </td>
                              <td className="align-middle text-sm font-normal px-6 py-4 whitespace-nowrap  text-center">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                  {item?.name}
                                </span>
                              </td>
                              <td className="text-sm font-normal text-center px-6 py-4 whitespace-nowrap">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                  {item?.cnic}
                                </span>
                              </td>

                              <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                  {item?.depositAmount}
                                </span>
                              </td>
                              <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                  {item?.amount}
                                </span>
                              </td>

                              <td className="align-middle  text-sm font-normal px-6 py-4 whitespace-nowrap">
                                {item?.status2 === 'paid' ? (
                                  <span className=" text-base  text-green bg-lightGray py-2 cursor-not-allowed px-3 leading-none  flex justify-center  whitespace-nowrap    bg-green-200  rounded-full">
                                    Paid
                                  </span>
                                ) : (
                                  <span
                                    onClick={() => update_Rewards(item?._id, item?.investment)}
                                    className=" cursor-pointer text-base flex  gap-2 items-center  text-[#EF434E]  bg-[#fdeced] py-2 px-3 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                    Mark as Paid
                                    {loading === item?._id ? (
                                      <svg
                                        className="animate-spin h-5 w-5 text-gray-700"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24">
                                        <circle
                                          className="opacity-25"
                                          cx="12"
                                          cy="12"
                                          r="10"
                                          stroke="currentColor"
                                          strokeWidth="4"
                                        />
                                        <path
                                          className="opacity-75"
                                          fill="currentColor"
                                          d="M4 12a8 8 0 018-8v8H4z"
                                        />
                                      </svg>
                                    ) : (
                                      ''
                                    )}
                                  </span>
                                )}
                              </td>
                              <td className="align-middle  cursor-pointer text-sm font-normal px-6  whitespace-nowrap">
                                <span
                                  onClick={() => removeFunction(item?._id)}
                                  className=" text-base text-black   leading-none  cursor-pointer  whitespace-nowrap    bg-green-200  rounded-full">
                                  <RiDeleteBin5Line className=" text-[#d33]" />
                                </span>
                              </td>
                            </tr>
                          </>
                        );
                        // }
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className=" flex justify-end items-center  py-4 gap-6">
            <button
              className={`${
                currentPage === 1 ? 'bg-gray-500  text-white' : 'bg-secondary text-white'
              } flex justify-center items-center  w-10 h-10 rounded-md`}
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}>
              <FaAngleLeft size={20} />
            </button>
            <span className="  text-primary  font-semibold">
              {currentPage} of {Math.ceil(filteredDeposits.length / itemsPerPage)}
            </span>
            <button
              className={`${
                currentPage * itemsPerPage >= filteredDeposits.length
                  ? 'bg-gray-500  text-white'
                  : 'bg-secondary text-white'
              } flex justify-center items-center  w-10 h-10 rounded-md`}
              disabled={currentPage * itemsPerPage >= filteredDeposits.length}
              onClick={() => handlePageChange(currentPage + 1)}>
              <FaAngleRight size={20} />
            </button>
          </div>
        </div>
      </section>
    </Super_admin_web_protal>
  );
};

export default Rewards;
