import React, { useEffect, useState, useTransition } from 'react';
import { FiPlus } from 'react-icons/fi';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import { IoSearchOutline } from 'react-icons/io5';
import AddWorkersList from './AddWorkersList';
import Manager_web_portal from '../../../layout/Manager_web_portal';
import { HiOutlineArrowLeft } from 'react-icons/hi';
import { useAllworkersQuery } from '../../../store/services/workerService';
import axios from 'axios';
import { Base_url } from '../../../utils/Base_url';
import ViewSlip from './ViewSlip';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
const WorkersList = () => {
  const { data, isFetching } = useAllworkersQuery();
  console.log(data, isFetching);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const [getData, setGetData] = useState({});

  const [isModalOpen2, setIsModalOpen2] = useState(false);

  const openModal2 = () => {
    setIsModalOpen2(true);
  };

  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const handleStatusChange = async (workerId, newStatus) => {
    const params = {
      status: newStatus
    };
    axios
      .post(`${Base_url}/v1/manager/update-worker-status/${workerId}`, params)
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });

    axios.post();
  };

  const [searchTerm, setSearchTerm] = useState('');

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [filteredDeposits, setFilteredDeposits] = useState([]);
  const currentPageData = filteredDeposits.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (newPage) => setCurrentPage(newPage);

  useEffect(() => {
    filterDeposits();
  }, [data, searchTerm]);

  const filterDeposits = () => {
    let filtered = data?.workers || [];

    if (searchTerm.trim()) {
      const lowercasedSearchTerm = searchTerm.toLowerCase();
      filtered = filtered.filter((val) => {
        const investmentIdMatch = val?.name?.toLowerCase().includes(lowercasedSearchTerm);
        const managerIdMatch = val?.cnic?.toLowerCase().includes(lowercasedSearchTerm);

        return investmentIdMatch || managerIdMatch;
      });
    }

    setFilteredDeposits(filtered);
    setCurrentPage(1);
  };

  return (
    <Manager_web_portal
      language={
        <>
          <p className=" sm:block hidden">English | Urdu</p>
        </>
      }
      ToggleBtn={
        <div className=" flex gap-4 items-center">
          <HiOutlineArrowLeft />
          <h2 className="">Workers List</h2>
        </div>
      }
      title_props={
        <>
          <div className=" w-96 relative">
            <Input
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder={''}
              className={' border bg-white w-full rounded-full'}
            />

            <Button
              Icon={<IoSearchOutline className=" text-white text-xl" />}
              label={'search'}
              className={
                ' absolute top-0 right-0 bg-secondary  text-white uppercase font-normal rounded-full'
              }
            />
          </div>
        </>
      }
    >
      <div className=" sm:flex block    justify-between items-center">
        <div>
          <h2 className=" sm:block hidden">Workers List</h2>
        </div>

        <ViewSlip isModalOpen={isModalOpen2} setIsModalOpen={setIsModalOpen2} getData={getData} />

        <div className=" flex gap-3 items-center">
          <Button
            onClick={openModal}
            Icon={<FiPlus />}
            label={'Register Worker'}
            className={' bg-secondary text-white  font-normal py-3.5 rounded-full'}
          />
        </div>
      </div>

      <AddWorkersList
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        // setUsers={setUsers}
      />

      <section className="mb-20 mt-7 text-gray-800">
        <div className="block rounded-lg ">
          <div className="flex overflow-hidden flex-col">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full sm:px-6 lg:px-8">
                <div className="overflow-hidden">
                  <table className="min-w-full mb-0">
                    <thead className=" ">
                      <tr className=" rounded-lg whitespace-nowrap ">
                        <th scope="col" className=" text-sm text-darkGray    font-medium px-6 ">
                          Name
                        </th>
                        <th scope="col" className=" text-sm text-darkGray   font-medium px-6 ">
                          Email
                        </th>

                        <th scope="col" className="text-sm  text-darkGray   font-medium px-6 ">
                          Phone number
                        </th>

                        <th scope="col" className="text-sm  text-darkGray     font-medium px-6 ">
                          CNIC No
                        </th>
                        <th scope="col" className="text-sm    text-darkGray   font-medium px-6 ">
                          CNIC Copy
                        </th>

                        {/* <th
                          scope="col"
                          className="text-sm    text-darkGray   font-medium px-6 "
                        >
                          Email Address
                        </th> */}

                        <th scope="col" className="text-sm    text-darkGray   font-medium px-6 ">
                          Password
                        </th>

                        <th scope="col" className="text-sm    text-darkGray   font-medium px-6 ">
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {currentPageData?.map((item, index) => {
                        return (
                          <>
                            <tr className="bg-white border-8    border-[#F6F6F6]  rounded-md ">
                              <td className="align-middle text-sm font-normal px-6 py-4 whitespace-nowrap  text-center">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                  {item?.name}
                                </span>
                              </td>
                              <td className="text-sm font-normal text-center px-6 py-4 whitespace-nowrap">
                                <span className=" text-base  text-black py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                  {item?.email}
                                </span>
                              </td>
                              <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                  {item?.phone}
                                </span>
                              </td>
                              <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                  {item?.cnic}
                                </span>
                              </td>

                              <td className="align-middle cursor-pointer  text-sm font-normal px-6 py-4 whitespace-nowrap">
                                <span
                                  onClick={() => {
                                    openModal2();
                                    setGetData(item);
                                  }}
                                  className=" text-base text-secondary  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full"
                                >
                                  {item?.cnicImage?.slice(-6)}
                                </span>
                              </td>
                              {/* <td className="align-middle  text-sm font-normal px-6 py-4 whitespace-nowrap">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                  {item?.email}
                                </span>
                              </td> */}
                              <td className="align-middle  text-sm font-normal px-6 py-4 whitespace-nowrap">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                  {item?.password}
                                </span>
                              </td>
                              <td className="align-middle cursor-pointer  text-sm font-normal px-6 py-2 whitespace-nowrap">
                                <select
                                  defaultValue={item.status}
                                  onChange={(e) => handleStatusChange(item._id, e.target.value)}
                                  className="text-base bg-lightGray cursor-pointer  text-black  py-2 px-3 leading-none  whitespace-nowrap    bg-green-200  rounded-full"
                                >
                                  <option value={true}>Active</option>
                                  <option value={false}>Inactive</option>
                                </select>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className=" flex justify-end items-center  py-4 gap-6">
            <button
              className={`${
                currentPage === 1 ? 'bg-gray-500  text-white' : 'bg-secondary text-white'
              } flex justify-center items-center  w-10 h-10 rounded-md`}
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              <FaAngleLeft size={20} />
            </button>
            <span className="  text-primary  font-semibold">
              {currentPage} of {Math.ceil(filteredDeposits.length / itemsPerPage)}
            </span>
            <button
              className={`${
                currentPage * itemsPerPage >= filteredDeposits.length
                  ? 'bg-gray-500  text-white'
                  : 'bg-secondary text-white'
              } flex justify-center items-center  w-10 h-10 rounded-md`}
              disabled={currentPage * itemsPerPage >= filteredDeposits.length}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              <FaAngleRight size={20} />
            </button>
          </div>
        </div>
      </section>
    </Manager_web_portal>
  );
};

export default WorkersList;
