import React, { useEffect, useState } from 'react';
import Wrapper from '../../../layout/Wrapper';
import axios from 'axios';
import { Base_url } from '../../../utils/Base_url';
import Swal from 'sweetalert2';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import Manager_web_portal from '../../../layout/Manager_web_portal';
import { IoSearchOutline } from 'react-icons/io5';
import { HiOutlineArrowLeft } from 'react-icons/hi';
import { useAllbranchsQuery } from '../../../store/services/branchService';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

const BranchList = () => {
  const { data, isFetching } = useAllbranchsQuery();
  console.log(data, isFetching);

  const [searchTerm, setSearchTerm] = useState('');

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [filteredDeposits, setFilteredDeposits] = useState([]);
  const currentPageData = filteredDeposits.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (newPage) => setCurrentPage(newPage);

  useEffect(() => {
    filterDeposits();
  }, [data, searchTerm]);

  const filterDeposits = () => {
    let filtered = data?.branches || [];

    if (searchTerm.trim()) {
      const lowercasedSearchTerm = searchTerm.toLowerCase();
      filtered = filtered.filter((val) => {
        const investmentIdMatch = val?.city?.toLowerCase().includes(lowercasedSearchTerm);
        const managerIdMatch = val?.location?.toLowerCase().includes(lowercasedSearchTerm);

        return investmentIdMatch || managerIdMatch;
      });
    }

    setFilteredDeposits(filtered);
    setCurrentPage(1);
  };

  return (
    <Manager_web_portal
      language={
        <>
          <p className=" sm:block hidden">English | Urdu</p>
        </>
      }
      ToggleBtn={
        <div className=" flex gap-4 items-center">
          <HiOutlineArrowLeft />
          <h2 className="">Branch List</h2>
        </div>
      }
      title_props={
        <>
          <div className=" w-96 relative">
            <Input
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder={''}
              className={' border bg-white w-full rounded-full'}
            />

            <Button
              Icon={<IoSearchOutline className=" text-white text-xl" />}
              label={'search'}
              className={
                ' absolute top-0 right-0 bg-secondary text-white uppercase font-normal rounded-full'
              }
            />
          </div>
        </>
      }
    >
      <div className="  w-full  mb-4  md:hidden block relative">
        <Input
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder={''}
          className={' border bg-white w-full rounded-full'}
        />

        <Button
          Icon={<IoSearchOutline className=" text-white text-xl" />}
          label={'search'}
          className={
            ' absolute top-0 right-0 bg-secondary text-white uppercase font-normal rounded-full'
          }
        />
      </div>

      <div className=" sm:flex block    justify-between items-center">
        <div>
          <h2 className=" sm:block  hidden">Branch List</h2>
        </div>
      </div>
      <section className="mb-20 mt-7 text-gray-800">
        <div className="block rounded-lg ">
          <div className="flex flex-col">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full sm:px-6 lg:px-8">
                <div className="overflow-hidden">
                  <table className="min-w-full mb-0">
                    <thead className=" ">
                      <tr className=" rounded-lg whitespace-nowrap ">
                        <th scope="col" className=" text-sm  text-darkGray font-semibold px-6 py-4">
                          City
                        </th>
                        <th
                          scope="col"
                          className=" text-sm text-darkGray    font-semibold px-6 py-4"
                        >
                          Location
                        </th>
                        <th
                          scope="col"
                          className=" text-sm text-darkGray   font-semibold px-6 py-4"
                        >
                          Email
                        </th>

                        <th
                          scope="col"
                          className="text-sm  text-darkGray   font-semibold px-6 py-4"
                        >
                          Contact number 1
                        </th>

                        <th
                          scope="col"
                          className="text-sm  text-darkGray     font-semibold px-6 py-4"
                        >
                          Contact number 2
                        </th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {currentPageData?.map((item, index) => {
                        return (
                          <>
                            <tr className="bg-white border-8 border-lightGray  rounded-md ">
                              <td
                                scope="row"
                                className="text-sm font-normal px-6 py-4   whitespace-nowrap "
                              >
                                <p className="mb-0.5 font-medium text-black">{item?.city}</p>
                              </td>
                              <td className="align-middle text-sm font-normal px-6 py-4 whitespace-nowrap  text-center">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                  {item?.location}
                                </span>
                              </td>
                              <td className="text-sm font-normal text-center px-6 py-4 whitespace-nowrap">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                  {item?.email}
                                </span>
                              </td>
                              <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                  {item?.contactNumber1}
                                </span>
                              </td>
                              <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                  {item?.contactNumber2}
                                </span>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className=" flex justify-end items-center  py-4 gap-6">
            <button
              className={`${
                currentPage === 1 ? 'bg-gray-500  text-white' : 'bg-secondary text-white'
              } flex justify-center items-center  w-10 h-10 rounded-md`}
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              <FaAngleLeft size={20} />
            </button>
            <span className="  text-primary  font-semibold">
              {currentPage} of {Math.ceil(filteredDeposits.length / itemsPerPage)}
            </span>
            <button
              className={`${
                currentPage * itemsPerPage >= filteredDeposits.length
                  ? 'bg-gray-500  text-white'
                  : 'bg-secondary text-white'
              } flex justify-center items-center  w-10 h-10 rounded-md`}
              disabled={currentPage * itemsPerPage >= filteredDeposits.length}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              <FaAngleRight size={20} />
            </button>
          </div>
        </div>
      </section>
    </Manager_web_portal>
  );
};

export default BranchList;
