import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Base_url } from '../../../utils/Base_url';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import { IoSearchOutline } from 'react-icons/io5';
import { HiOutlineArrowLeft } from 'react-icons/hi';
import { useAllrequestsQuery } from '../../../store/services/requestService';
import moment from 'moment';
import Super_admin_web_protal from '../../../layout/Super_admin_web_portal';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import WithdrawalRequests from './WithdrawalRequests';
const Requests = () => {
  const { data, isFetching } = useAllrequestsQuery();
  console.log(data, isFetching);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [filteredDeposits, setFilteredDeposits] = useState([]);
  const currentPageData = filteredDeposits.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (newPage) => setCurrentPage(newPage);

  useEffect(() => {
    filterDeposits();
  }, [data, searchTerm]);

  const filterDeposits = () => {
    // Create a copy of the data or initialize an empty array if no data
    let filtered = data?.data ? [...data.data] : [];

    // Filter based on the search term if it exists
    if (searchTerm.trim()) {
      const lowercasedSearchTerm = searchTerm.toLowerCase();
      filtered = filtered.filter((val) => {
        const investmentIdMatch = val?.investmentId?.toLowerCase().includes(lowercasedSearchTerm);
        const managerIdMatch = val?.managerData?.name?.toLowerCase().includes(lowercasedSearchTerm);
        const statusMatch = val?.status?.toLowerCase().includes(lowercasedSearchTerm);

        return investmentIdMatch || managerIdMatch || statusMatch;
      });
    }

    // Sort the filtered results to place 'pending' statuses at the top
    filtered.sort((a, b) => {
      if (a.status === 'pending' && b.status !== 'pending') {
        return -1; // a (pending) comes first
      } else if (a.status !== 'pending' && b.status === 'pending') {
        return 1; // b (pending) comes first
      }
      return 0; // keep original order for other statuses
    });

    // Store the filtered deposits in state
    setFilteredDeposits(filtered);
    setCurrentPage(1); // Reset to the first page
  };

  const handleStatusChange = async (workerId, newStatus) => {
    console.log(workerId, newStatus);
    const params = {
      status: newStatus,
      requestId: workerId
    };
    axios
      .post(`${Base_url}/v1/admin/update-manager-request`, params)
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });

    axios.post();
  };

  const [chnageRequest, setChangeRequest] = useState('deposit');
  return (
    <Super_admin_web_protal
      language={
        <>
          <p className=" sm:block hidden">English | Urdu</p>
        </>
      }
      ToggleBtn={
        <div className=" flex gap-4 items-center">
          <HiOutlineArrowLeft />
          <h2 className="">Requests</h2>
        </div>
      }
      title_props={
        <>
          <div className=" w-96 relative">
            <Input
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder={''}
              className={' border bg-white w-full rounded-full'}
            />

            <Button
              Icon={<IoSearchOutline className=" text-white text-xl" />}
              label={'search'}
              className={
                ' absolute top-0 right-0 bg-secondary  text-white uppercase font-normal rounded-full'
              }
            />
          </div>
        </>
      }
    >
      <div className="  w-full  mb-4  md:hidden block relative">
        <Input
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder={''}
          className={' border bg-white w-full rounded-full'}
        />

        <Button
          Icon={<IoSearchOutline className=" text-white text-xl" />}
          label={'search'}
          className={
            ' absolute top-0 right-0 bg-secondary text-white uppercase font-normal rounded-full'
          }
        />
      </div>
      <div className=" sm:flex block    justify-between items-center">
        <div>
          <h2 className=" sm:block hidden">Requests</h2>
        </div>
      </div>

      <div className=" my-4">
        <ul className=" p-0 flex gap-12 items-center">
          <il onClick={() => setChangeRequest('deposit')} className="">
            <span
              className={`m-0 text-black text-lg  ${
                chnageRequest === 'deposit' ? 'border-secondary border-b-2 font-bold' : ''
              }`}
            >
              Deposits
            </span>
          </il>
          {/* <il onClick={() => setChangeRequest("withdrawal")} className="">
            <span
              className={`m-0 text-black text-lg  ${
                chnageRequest === "withdrawal"
                  ? "border-secondary border-b-2 font-bold"
                  : ""
              }`}
            >
              Withdrawal
            </span>
          </il> */}
        </ul>
      </div>
      {chnageRequest === 'deposit' ? (
        <section className="mb-20 mt-5 text-gray-800">
          <div className="block rounded-lg ">
            <div className="flex flex-col overflow-hidden">
              <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="">
                    <table className="min-w-full mb-0">
                      <thead className=" ">
                        <tr className=" rounded-lg whitespace-nowrap ">
                          <th
                            scope="col"
                            className=" text-sm  text-darkGray font-semibold px-6 py-4"
                          >
                            Deposit Date
                          </th>
                          <th
                            scope="col"
                            className=" text-sm text-darkGray    font-semibold px-6 py-4"
                          >
                            Investment ID
                          </th>
                          <th
                            scope="col"
                            className=" text-sm text-darkGray    font-semibold px-6 py-4"
                          >
                            Manager Name
                          </th>
                          <th
                            scope="col"
                            className=" text-sm text-darkGray   font-semibold px-6 py-4"
                          >
                            Request Description
                          </th>

                          <th
                            scope="col"
                            className="text-sm  text-darkGray   font-semibold px-6 py-4"
                          >
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody className="">
                        {currentPageData?.map((item, index) => {
                          return (
                            <>
                              <tr className="bg-white border-t-8  border-b-8 border-lightGray  rounded-md ">
                                <th
                                  scope="row"
                                  className="text-sm font-normal px-6 py-4   whitespace-nowrap "
                                >
                                  {item?.status === 'pending' ? (
                                    <p className="mb-0.5 font-medium text-gray-400">
                                      {moment(item?.createdAt).format('DD/MM/YYYY')}{' '}
                                    </p>
                                  ) : (
                                    <p className="mb-0.5 font-medium text-black">
                                      {moment(item?.createdAt).format('DD/MM/YYYY')}{' '}
                                    </p>
                                  )}
                                </th>
                                <td className="align-middle text-sm font-normal px-6 py-4 whitespace-nowrap  text-center">
                                  {item?.status === 'pending' ? (
                                    <span className=" text-base text-gray-400  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                      {item?.investmentId}
                                    </span>
                                  ) : (
                                    <span className=" text-base text-black  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                      {item?.investmentId}
                                    </span>
                                  )}
                                </td>
                                <td className="align-middle text-sm font-normal px-6 py-4 whitespace-nowrap  text-center">
                                  {item?.status === 'pending' ? (
                                    <span className=" text-base text-gray-400  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                      {item?.managerData?.name}
                                    </span>
                                  ) : (
                                    <span className=" text-base text-black  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                      {item?.managerData?.name}
                                    </span>
                                  )}
                                </td>
                                <td className="text-sm font-normal text-center px-6 py-4 whitespace-nowrap">
                                  {item?.status === 'pending' ? (
                                    <span className=" text-base text-gray-400  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                      {item?.details}
                                    </span>
                                  ) : (
                                    <span className=" text-base text-black  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                      {item?.details}
                                    </span>
                                  )}
                                </td>
                                <td className="align-middle  text-sm font-normal px-6 py-2 whitespace-nowrap">
                                  <select
                                    defaultValue={item.status}
                                    onChange={(e) => handleStatusChange(item._id, e.target.value)}
                                    className="text-base  text-green bg-lightGray  cursor-pointer text-black  py-2 px-3 leading-none  whitespace-nowrap    bg-green-200  rounded-full"
                                  >
                                    <option value={'pending'}>pending</option>
                                    <option value={'approved'}>resolved</option>
                                  </select>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className=" flex justify-end items-center  py-4 gap-6">
              <button
                className={`${
                  currentPage === 1 ? 'bg-gray-500  text-white' : 'bg-secondary text-white'
                } flex justify-center items-center  w-10 h-10 rounded-md`}
                disabled={currentPage === 1}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                <FaAngleLeft size={20} />
              </button>
              <span className="  text-primary  font-semibold">
                {currentPage} of {Math.ceil(filteredDeposits.length / itemsPerPage)}
              </span>
              <button
                className={`${
                  currentPage * itemsPerPage >= filteredDeposits.length
                    ? 'bg-gray-500  text-white'
                    : 'bg-secondary text-white'
                } flex justify-center items-center  w-10 h-10 rounded-md`}
                disabled={currentPage * itemsPerPage >= filteredDeposits.length}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                <FaAngleRight size={20} />
              </button>
            </div>
          </div>
        </section>
      ) : (
        <WithdrawalRequests />
      )}
    </Super_admin_web_protal>
  );
};

export default Requests;
