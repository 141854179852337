import { createSlice } from '@reduxjs/toolkit';
// import { jwtDecode } from "jwt-decode";
const adminToken = localStorage.getItem('adminToken');
const managerToken = localStorage.getItem('managerToken');
const workerToken = localStorage.getItem('workerToken');
const investorToken = localStorage.getItem('investorToken');

const authReducer = createSlice({
  name: 'authReducer',
  initialState: {
    adminToken: adminToken,
    managerToken: managerToken,
    workerToken: workerToken,
    investorToken: investorToken
  },
  reducers: {
    setAdminToken: (state, action) => {
      state.adminToken = action.payload;
      localStorage.setItem('adminToken', action.payload);
    },
    setManagerToken: (state, action) => {
      state.managerToken = action.payload;
      localStorage.setItem('managerToken', action.payload);
    },
    setWorkerToken: (state, action) => {
      state.workerToken = action.payload;
      localStorage.setItem('workerToken', action.payload);
    },
    setInvestorToken: (state, action) => {
      state.investorToken = action.payload;
      localStorage.setItem('investorToken', action.payload);
    },
    logout: (state, { payload }) => {
      localStorage.removeItem(payload);
      if (payload === 'adminToken') {
        state.adminToken = null;
      } else if (payload === 'workerToken') {
        state.workerToken = null;
      } else if (payload === 'managerToken') {
        state.managerToken = null;
      } else if (payload === 'investorToken') {
        state.investorToken = null;
      }
    }
  }
});
export const { setAdminToken, setManagerToken, setInvestorToken, setWorkerToken, logout } =
  authReducer.actions;
export default authReducer.reducer;
