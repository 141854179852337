import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const investorService = createApi({
  reducerPath: 'investor',
  tagTypes: 'investors',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://welcome-habibi-backend.vercel.app/v1/'
  }),
  endpoints: (builder) => {
    return {
      create: builder.mutation({
        query: (name) => {
          return {
            url: 'worker/create-investor',
            method: 'POST',
            body: name
          };
        }
      }),
      enroll: builder.mutation({
        query: (name) => {
          return {
            url: 'worker/enroll',
            method: 'POST',
            body: name
          };
        }
      }),
      updateinvestor: builder.mutation({
        query: (data) => {
          return {
            url: `update/${data.id}`,
            method: 'PUT',
            body: { name: data.name }
          };
        }
      }),
      deleteinvestor: builder.mutation({
        query: (id) => {
          return {
            url: `delete/${id}`,
            method: 'DELETE'
          };
        }
      }),

      fetchinvestor: builder.mutation({
        query: (name) => {
          return {
            url: `worker/my-investor`,
            method: 'POST',
            body: name
          };
        }
      }),
      fetchmyinvestor: builder.query({
        query: (id) => {
          return {
            url: `worker/my-investor/${id}`,
            method: 'GET'
            // body: name,
          };
        }
      }),
      allinvestors: builder.query({
        query: () => {
          return {
            url: 'worker/investor',
            method: 'GET'
          };
        }
      }),
      investorprofile: builder.query({
        query: (id) => {
          return {
            url: `worker/investor-profile/${id}`,
            method: 'GET'
          };
        }
      })
    };
  }
});
export const {
  useCreateMutation,
  useEnrollMutation,
  useFetchinvestorMutation,
  useFetchmyinvestorQuery,
  useAllinvestorsQuery,
  useInvestorprofileQuery,
  useUpdateinvestorMutation,
  useDeleteinvestorMutation
} = investorService;
export default investorService;
