import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const depositService = createApi({
  reducerPath: 'deposit',
  tagTypes: 'deposits',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://welcome-habibi-backend.vercel.app/v1/'
  }),
  endpoints: (builder) => {
    return {
      create: builder.mutation({
        query: (name) => {
          return {
            url: 'worker/deposit',
            method: 'POST',
            body: name
          };
        }
      }),
      updatedeposit: builder.mutation({
        query: (data) => {
          return {
            url: `update/${data.id}`,
            method: 'PUT',
            body: { name: data.name }
          };
        }
      }),
      deletedeposit: builder.mutation({
        query: (id) => {
          return {
            url: `delete/${id}`,
            method: 'DELETE'
          };
        }
      }),

      fetchdeposit: builder.query({
        query: () => {
          return {
            url: `worker/all-deposit`,
            method: 'GET'
          };
        }
      }),
      fetchallmydeposit: builder.query({
        query: (id) => {
          return {
            url: `worker/all-my-deposit/${id}`,
            method: 'GET'
          };
        }
      }),
      alldeposits: builder.query({
        query: (id) => {
          return {
            url: `worker/total-deposit/${id}`,
            method: 'GET'
          };
        }
      }),
      todaydeposits: builder.query({
        query: (id) => {
          return {
            url: `worker/today-deposit/${id}`,
            method: 'GET'
          };
        }
      })
    };
  }
});
export const {
  useCreateMutation,
  useFetchdepositQuery,
  useFetchallmydepositQuery,
  useAlldepositsQuery,
  useTodaydepositsQuery,
  useUpdatedepositMutation,
  useDeletedepositMutation
} = depositService;
export default depositService;
