import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const eventService = createApi({
  reducerPath: 'event',
  tagTypes: 'events',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://welcome-habibi-backend.vercel.app/v1/'
  }),
  endpoints: (builder) => {
    return {
      create: builder.mutation({
        query: (name) => {
          return {
            url: 'manager/create-event',
            method: 'POST',
            body: name
          };
        }
      }),
      updateevent: builder.mutation({
        query: (data) => {
          return {
            url: `update/${data.id}`,
            method: 'PUT',
            body: { name: data.name }
          };
        }
      }),
      deleteevent: builder.mutation({
        query: (id) => {
          return {
            url: `delete/${id}`,
            method: 'DELETE'
          };
        }
      }),

      fetchevent: builder.query({
        query: (id) => {
          return {
            url: `worker/all-event/${id}`,
            method: 'GET'
          };
        }
      }),
      allevents: builder.query({
        query: () => {
          return {
            url: `manager/all-event`,
            method: 'GET'
          };
        }
      })
    };
  }
});
export const {
  useCreateMutation,
  useFetcheventQuery,
  useAlleventsQuery,
  useUpdateeventMutation,
  useDeleteeventMutation
} = eventService;
export default eventService;
