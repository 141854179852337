import { useState } from 'react';
import WorkerNav from '../components/navbar/workerNav/WorkerNav';
import ManagerSidebar from '../components/sidebar/managerSidebar/ManagerSidebar';
import ManagerNav from '../components/navbar/managerNav/ManagerNav';
const Manager_web_portal = ({ children, title_props, ToggleBtn, language }) => {
  const [side, setSide] = useState('-left-64 md:left-0');
  console.log(side);
  const openSidebar = () => {
    setSide('left-0 md:-left-64');
  };
  const closeSidebar = () => {
    setSide('-left-64 md:left-0');
  };

  return (
    <>
      <ManagerSidebar side={side} closeSidebar={closeSidebar} />
      <ManagerNav
        title_props={title_props}
        openSidebar={openSidebar}
        closeSidebar={closeSidebar}
        ToggleBtn={ToggleBtn}
        language={language}
        side={side}
      />
      <section
        className={`ml-0 ${
          side === 'left-0 md:-left-64' ? 'ml-0 ' : 'ml-0 md:ml-64'
        }  bg-white min-h-screen md:px-4 px-0`}
      >
        <div className=" text-black pt-32   min-h-screen bg-lightGray  h-full px-4 py-6">
          {children}
        </div>
      </section>
    </>
  );
};
export default Manager_web_portal;
