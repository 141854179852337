import React, { useEffect, useState } from 'react';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import { FiPlus } from 'react-icons/fi';
import AddRequests from './AddRequest';
import Manager_web_portal from '../../../layout/Manager_web_portal';
import { IoSearchOutline } from 'react-icons/io5';
import { HiOutlineArrowLeft } from 'react-icons/hi';
import { useAllrequestsQuery } from '../../../store/services/requestService';
import moment from 'moment';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
const Requests = () => {
  const { data, isFetching } = useAllrequestsQuery();
  console.log(data, isFetching);

  const [users, setUsers] = useState([]);

  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  const [singleUser, setSingleUser] = useState({});
  const UpdateModal = () => {
    setIsUpdateOpen(true);
  };

  const closeUpdateModal = () => {
    setIsUpdateOpen(false);
  };

  console.log(isUpdateOpen);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [filteredDeposits, setFilteredDeposits] = useState([]);
  const currentPageData = filteredDeposits.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (newPage) => setCurrentPage(newPage);

  useEffect(() => {
    filterDeposits();
  }, [data, searchTerm]);

  const filterDeposits = () => {
    // Create a copy of the data or initialize an empty array if no data
    let filtered = data?.data ? [...data.data] : [];

    // Filter based on the search term if it exists
    if (searchTerm.trim()) {
      const lowercasedSearchTerm = searchTerm.toLowerCase();
      filtered = filtered.filter((val) => {
        const investmentIdMatch = val?.investmentId?.toLowerCase().includes(lowercasedSearchTerm);
        const managerIdMatch = val?.managerData?.name?.toLowerCase().includes(lowercasedSearchTerm);
        const statusMatch = val?.status?.toLowerCase().includes(lowercasedSearchTerm);

        return investmentIdMatch || managerIdMatch || statusMatch;
      });
    }

    // Sort the filtered results to place 'pending' statuses at the top
    filtered.sort((a, b) => {
      if (a.status === 'pending' && b.status !== 'pending') {
        return -1; // a (pending) comes first
      } else if (a.status !== 'pending' && b.status === 'pending') {
        return 1; // b (pending) comes first
      }
      return 0; // keep original order for other statuses
    });

    // Store the filtered deposits in state
    setFilteredDeposits(filtered);
    setCurrentPage(1); // Reset to the first page
  };

  return (
    <Manager_web_portal
      language={
        <>
          <p className=" sm:block hidden">English | Urdu</p>
        </>
      }
      ToggleBtn={
        <div className=" flex gap-4 items-center">
          <HiOutlineArrowLeft />
          <h2 className="">Requests</h2>
        </div>
      }
      title_props={
        <>
          <div className=" w-96 relative">
            <Input
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder={''}
              className={' border bg-white w-full rounded-full'}
            />

            <Button
              Icon={<IoSearchOutline className=" text-white text-xl" />}
              label={'search'}
              className={
                ' absolute top-0 right-0 bg-secondary  text-white uppercase font-normal rounded-full'
              }
            />
          </div>
        </>
      }
    >
      <AddRequests isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} setUsers={setUsers} />

      <div className=" sm:flex block    justify-between items-center">
        <div>
          <h2 className=" sm:block hidden">Requests</h2>
        </div>

        <div className=" flex gap-3 items-center">
          <Button
            onClick={openModal}
            Icon={<FiPlus />}
            label={'Add Request'}
            className={' bg-secondary text-white  font-normal py-3 rounded-full'}
          />
        </div>
      </div>
      <section className="mb-20 mt-5 text-gray-800">
        <div className="block rounded-lg ">
          <div className="flex overflow-hidden flex-col">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full sm:px-6 lg:px-8">
                <div className="">
                  <table className="min-w-full mb-0">
                    <thead className=" ">
                      <tr className=" rounded-lg whitespace-nowrap ">
                        <th scope="col" className=" text-sm  text-darkGray font-semibold px-6 py-4">
                          Deposit Date
                        </th>
                        <th
                          scope="col"
                          className=" text-sm text-darkGray    font-semibold px-6 py-4"
                        >
                          Investment ID
                        </th>
                        <th
                          scope="col"
                          className=" text-sm text-darkGray    font-semibold px-6 py-4"
                        >
                          Manager
                        </th>
                        <th
                          scope="col"
                          className=" text-sm text-darkGray   font-semibold px-6 py-4"
                        >
                          Request Description
                        </th>

                        <th
                          scope="col"
                          className="text-sm  text-darkGray   font-semibold px-6 py-4"
                        >
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {currentPageData?.map((item, index) => {
                        return (
                          <>
                            <tr className="bg-white border-t-8  border-b-8 border-lightGray  rounded-md ">
                              <th
                                scope="row"
                                className="text-sm font-normal px-6 py-4   whitespace-nowrap "
                              >
                                {item?.status === 'pending' ? (
                                  <p className="mb-0.5 font-medium text-gray-400">
                                    {moment(item?.createdAt).format('DD/MM/YYYY')}
                                  </p>
                                ) : (
                                  <p className="mb-0.5 font-medium text-black">
                                    {moment(item?.createdAt).format('DD/MM/YYYY')}
                                  </p>
                                )}
                              </th>
                              <td className="align-middle text-sm font-normal px-6 py-4 whitespace-nowrap  text-center">
                                {item?.status === 'pending' ? (
                                  <span className=" text-base text-gray-400  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                    {item?.investmentId}
                                  </span>
                                ) : (
                                  <span className=" text-base text-black  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                    {item?.investmentId}
                                  </span>
                                )}
                              </td>
                              <td className="align-middle text-sm font-normal px-6 py-4 whitespace-nowrap  text-center">
                                {item?.status === 'pending' ? (
                                  <span className=" text-base text-gray-400  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                    {item?.managerData?.name}
                                  </span>
                                ) : (
                                  <span className=" text-base text-black  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                    {item?.managerData?.name}
                                  </span>
                                )}
                              </td>
                              <td className="text-sm font-normal text-center px-6 py-4 whitespace-nowrap">
                                {item?.status === 'pending' ? (
                                  <span className=" text-base text-gray-400  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                    {item?.details}
                                  </span>
                                ) : (
                                  <span className=" text-base text-black  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                    {item?.details}
                                  </span>
                                )}
                              </td>
                              <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                {item?.status === 'pending' ? (
                                  <span className=" text-base text-black  bg-lightGray px-3  py-2.5  leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                    {item?.status}
                                  </span>
                                ) : (
                                  <span className=" text-base text-green bg-lightGray  px-3  py-2.5  leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                    {item?.status}
                                  </span>
                                )}
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className=" flex justify-end items-center  py-4 gap-6">
            <button
              className={`${
                currentPage === 1 ? 'bg-gray-500  text-white' : 'bg-secondary text-white'
              } flex justify-center items-center  w-10 h-10 rounded-md`}
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              <FaAngleLeft size={20} />
            </button>
            <span className="  text-primary  font-semibold">
              {currentPage} of {Math.ceil(filteredDeposits.length / itemsPerPage)}
            </span>
            <button
              className={`${
                currentPage * itemsPerPage >= filteredDeposits.length
                  ? 'bg-gray-500  text-white'
                  : 'bg-secondary text-white'
              } flex justify-center items-center  w-10 h-10 rounded-md`}
              disabled={currentPage * itemsPerPage >= filteredDeposits.length}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              <FaAngleRight size={20} />
            </button>
          </div>
        </div>
      </section>
    </Manager_web_portal>
  );
};

export default Requests;
