import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Base_url } from '../../utils/Base_url';

const managerService = createApi({
  reducerPath: 'manager',
  tagTypes: 'managers',
  baseQuery: fetchBaseQuery({
    baseUrl: `${Base_url}/v1/admin/`
  }),
  endpoints: (builder) => {
    return {
      create: builder.mutation({
        query: (name) => {
          return {
            url: 'create-manager',
            method: 'POST',
            body: name
          };
        }
      }),
      updatemanager: builder.mutation({
        query: (data) => {
          return {
            url: `update/${data.id}`,
            method: 'POST',
            body: { name: data.name }
          };
        }
      }),
      deletemanager: builder.mutation({
        query: (id) => {
          return {
            url: `delete/${id}`,
            method: 'DELETE'
          };
        }
      }),

      fetchmanager: builder.query({
        query: (id) => {
          return {
            url: `get/${id}`,
            method: 'GET'
          };
        }
      }),
      allmanagers: builder.query({
        query: () => {
          return {
            url: 'all-managers',
            method: 'GET'
          };
        }
      })
    };
  }
});
export const {
  useCreateMutation,
  useFetchmanagerQuery,
  useAllmanagersQuery,
  useUpdatemanagerMutation,
  useDeletemanagerMutation
} = managerService;
export default managerService;
