import { useState } from 'react';
import WorkerNav from '../components/navbar/workerNav/WorkerNav';
import WorkSidebar from '../components/sidebar/workerSidebar/WorkSidebar';
const Worker_web_protal = ({ children, title_props, ToggleBtn, language }) => {
  const [side, setSide] = useState('-left-64 md:left-0');
  console.log(side);
  const openSidebar = () => {
    setSide('left-0 md:-left-64');
  };
  const closeSidebar = () => {
    setSide('-left-64 md:left-0');
  };

  return (
    <>
      <WorkSidebar side={side} closeSidebar={closeSidebar} />
      <WorkerNav
        title_props={title_props}
        openSidebar={openSidebar}
        language={language}
        closeSidebar={closeSidebar}
        ToggleBtn={ToggleBtn}
        side={side}
      />
      <section
        className={`ml-0 ${
          side === 'left-0 md:-left-64' ? 'ml-0 ' : 'ml-0 md:ml-64'
        }  bg-white min-h-screen md:px-4 px-0`}
      >
        <div className="  h-full text-black min-h-screen pt-32 bg-lightGray    px-4 py-6">
          {children}
        </div>
      </section>
    </>
  );
};
export default Worker_web_protal;
