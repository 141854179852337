import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const branchService = createApi({
  reducerPath: 'branch',
  tagTypes: 'branchs',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://welcome-habibi-backend.vercel.app/v1/'
  }),
  endpoints: (builder) => {
    return {
      create: builder.mutation({
        query: (name) => {
          return {
            url: 'admin/create-branch',
            method: 'POST',
            body: name
          };
        }
      }),
      updatebranch: builder.mutation({
        query: (data) => {
          return {
            url: `admin/update-branch/${data.id}`,
            method: 'POST',
            body: { name: data.name }
          };
        }
      }),
      deletebranch: builder.mutation({
        query: (id) => {
          return {
            url: `delete/${id}`,
            method: 'DELETE'
          };
        }
      }),

      fetchbranch: builder.query({
        query: (id) => {
          return {
            url: `get/${id}`,
            method: 'GET'
          };
        }
      }),
      allbranchs: builder.query({
        query: () => {
          return {
            url: 'worker/all-branches',
            method: 'GET'
          };
        }
      })
    };
  }
});
export const {
  useCreateMutation,
  useFetchbranchQuery,
  useAllbranchsQuery,
  useUpdatebranchMutation,
  useDeletebranchMutation
} = branchService;
export default branchService;
