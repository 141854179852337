import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const collectionService = createApi({
  reducerPath: 'collection',
  tagTypes: 'collections',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://welcome-habibi-backend.vercel.app/v1/manager/'
  }),
  endpoints: (builder) => {
    return {
      create: builder.mutation({
        query: (name) => {
          return {
            url: 'create-collection',
            method: 'POST',
            body: name
          };
        }
      }),
      updatecollection: builder.mutation({
        query: (data) => {
          return {
            url: `update/${data.id}`,
            method: 'PUT',
            body: { name: data.name }
          };
        }
      }),
      deletecollection: builder.mutation({
        query: (id) => {
          return {
            url: `delete/${id}`,
            method: 'DELETE'
          };
        }
      }),

      fetchcollection: builder.query({
        query: (id) => {
          return {
            url: `get/${id}`,
            method: 'GET'
          };
        }
      }),
      allcollections: builder.query({
        query: () => {
          return {
            url: 'total-collection',
            method: 'GET'
          };
        }
      })
    };
  }
});
export const {
  useCreateMutation,
  useFetchcollectionQuery,
  useAllcollectionsQuery,
  useUpdatecollectionMutation,
  useDeletecollectionMutation
} = collectionService;
export default collectionService;
