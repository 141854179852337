import { Route, Routes } from 'react-router-dom';
import Dashboard from '../screens/manager/dashboard/Dashboard';
import UserManagement from '../screens/manager/userManagement';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdminLogin from '../screens/Login';
import Profits_and_deducations from '../screens/manager/profits_and_deductions';
import AddProfits_and_deductions from '../screens/manager/profits_and_deductions/AddProfits_and_deductions';
import Rewards from '../screens/manager/Rewards';
import Add_Rewards from '../screens/manager/Rewards/AddRewards';
import BranchList from '../screens/manager/BranchList';
import Requests from '../screens/manager/Requests';
import CompanyExpense from '../screens/manager/CompanyExpense';
import AddCompanyExpense from '../screens/manager/CompanyExpense/AddCompanyExpense';
import WhtGallery from '../screens/manager/WhtGallery';
import WorkGallery from '../screens/worker/wht_gallery/WhtGallery';
import My_portal from '../screens/worker/myportal/My_portal';
import Investors from '../screens/worker/investors/Investors';
import ManagerInvestors from '../screens/manager/Investors';
import WorkRewards from '../screens/worker/rewards/Rewards';
import Deposits from '../screens/worker/deposits/Deposits';
import Home from '../screens/Home/Home';
import Workers_BranchList from '../screens/worker/BranchList';
import MangerInvestorsDetails from '../screens/manager/InvestorsDetails';
import Collection from '../screens/manager/Collection';
import WorkersList from '../screens/manager/WorkersList';
import Admin_Dashboard from '../screens/superAdmin/dashboard/Dashboard';
import Managers from '../screens/superAdmin/managers';
import Super_WhtGallery from '../screens/superAdmin/WhtGallery';
import Admin_BranchList from '../screens/superAdmin/BranchList';
import Super_rewards from '../screens/superAdmin/Rewards';
import SuperCompanyExpense from '../screens/superAdmin/CompanyExpense';
import Super_Collection from '../screens/superAdmin/Collection';
import Super_WorkersList from '../screens/superAdmin/WorkersList';
import Super_Investors from '../screens/superAdmin/Investors';
import Super_Requests from '../screens/superAdmin/Requests';
import InvestorsDetails from '../screens/superAdmin/InvestorsDetails';
import WorkerInvestorsDetails from '../screens/worker/InvestorsDetails';
import PrivateWorkerRoute from './PrivateWorkerRoute';
import PrivateManagerRoute from './PrivateManagerRoute';
import PrivateAdminRoute from './PrivateAdminRoute';
import Plans from '../screens/superAdmin/Plans';
import View_plan from '../screens/superAdmin/Plans/View_plan';
import WorkerPlan from '../screens/worker/Plans';
import WorkerViewPlan from '../screens/worker/Plans/View_pan';
import InvestorPlans from '../screens/investor/plans';
import InvestorActivePlans from '../screens/investor/activePlan/inex';
import WithdrawalRequests from '../screens/investor/requests';
import MyDetails from '../screens/investor/myDetails';
import InvestorViewPlan from '../screens/investor/plans/ViewPlan';
import ViewDeposits from '../screens/investor/plans/ViewDeposits';
import InvestorHistory from '../screens/investor/history';
import InvestorWhtGallery from '../screens/investor/wht_gallery/WhtGallery';
import WorkerHistory from '../screens/worker/history';
import SuperAdminHistory from '../screens/superAdmin/history';
import PrivateInvestorRoute from './PrivateInvestorRoute';
import ViewPlanInvestorDetails from '../screens/superAdmin/Plans/ViewPlanInvestorDetails';
import WorkerPlanInvestorDetails from '../screens/worker/Plans/ViewPlanInvestorDetails';
import ViewPlanDetails from '../screens/investor/plans/viewPlanDetails';
import ManagerHistory from '../screens/manager/History';
import ManagerPlans from '../screens/manager/Plans';
import Manager_View_plan from '../screens/manager/Plans/View_Plan';
import Alerts from '../screens/manager/Alerts';
import LoginRedirectRoute from './LoginRedirectRoute';
import ResetPassword from '../screens/superAdmin/ResetPassoword';
import InvestmentDetail from '../screens/Home/InvestmentDetail';

function Routing() {
  return (
    <>
      <ToastContainer />
      <Routes>
        {/* login  */}

        <Route path="/" element={<Home />} />
        <Route path="/checkinvestment/:id" element={<Home />} />

        <Route
          path="/login"
          element={
            <LoginRedirectRoute>
              <AdminLogin />
            </LoginRedirectRoute>
          }
        />

        {/* super_admin routing */}
        <Route element={<PrivateAdminRoute />}>
          <Route path="/super_admin/plans" element={<Plans />} />
          <Route path="/super_admin/view/:id" element={<View_plan />} />
          <Route path="/super_admin/dashboard" element={<Admin_Dashboard />} />
          <Route path="/super_admin/managers" element={<Managers />} />
          <Route path="/super/rewards" element={<Super_rewards />} />
          <Route path="/super_admin/requests" element={<Super_Requests />} />
          <Route path="/super_admin/history/:id" element={<SuperAdminHistory />} />
          <Route
            path="/super_admin/plan_investor_details/:id1/:id2"
            element={<ViewPlanInvestorDetails />}
          />
          <Route path="/super_admin/investorsDetails/:id1/:id2" element={<InvestorsDetails />} />
          <Route path="/super_admin/compnay_expence" element={<SuperCompanyExpense />} />
          <Route path="/super_admin/wht_gallery" element={<Super_WhtGallery />} />
          <Route path="/super_admin/branch_list" element={<Admin_BranchList />} />
          <Route path="/super_admin/reset_password" element={<ResetPassword />} />
          <Route path="/super_admin/collection" element={<Super_Collection />} />
          <Route path="/super_admin_workers_list" element={<Super_WorkersList />} />
          <Route path="/super_admin/investors" element={<Super_Investors />} />
        </Route>
        {/* manager portal */}

        <Route element={<PrivateManagerRoute />}>
          <Route path="/manager/dashboard" element={<Dashboard />} />
          <Route path="/manager/user_management" element={<UserManagement />} />
          <Route path="/manager/managerPlans" element={<ManagerPlans />} />
          <Route path="/manager/manager_View_plan/:id" element={<Manager_View_plan />} />
          <Route path="/manager/investorsDetails/:id1/:id2" element={<MangerInvestorsDetails />} />
          <Route path="/manager/profits_and_deducations" element={<Profits_and_deducations />} />
          <Route
            path="/manager/add_profits_and_deducations"
            element={<AddProfits_and_deductions />}
          />
          <Route path="/manager/rewards" element={<Rewards />} />
          <Route path="/manager/add_rewards" element={<Add_Rewards />} />
          <Route path="/manager/collection" element={<Collection />} />
          <Route path="/manager/workers_list" element={<WorkersList />} />
          <Route path="/manager/branch_list" element={<BranchList />} />
          <Route path="/manager/investors" element={<ManagerInvestors />} />
          <Route path="/manager/requests" element={<Requests />} />
          <Route path="/manager/alerts" element={<Alerts />} />
          <Route path="/manager/history/:id" element={<ManagerHistory />} />
          <Route path="/manager/compnay_expence" element={<CompanyExpense />} />
          <Route path="/manager/add_compnay_expense" element={<AddCompanyExpense />} />
          <Route path="/manager/wht_gallery" element={<WhtGallery />} />
        </Route>

        {/* work routing */}
        <Route element={<PrivateWorkerRoute />}>
          <Route path="/workers/my_portal" element={<My_portal />} />
          <Route path="/workers/plan" element={<WorkerPlan />} />
          <Route path="/workers/view-pan/:id" element={<WorkerViewPlan />} />
          <Route path="/workers/investors" element={<Investors />} />
          <Route path="/workers/rewards" element={<WorkRewards />} />
          <Route path="/workers/deposits" element={<Deposits />} />
          <Route path="/workers/wht_gallery" element={<WorkGallery />} />
          <Route path="/workers/branch_list" element={<Workers_BranchList />} />
          <Route path="/workers/history/:id" element={<WorkerHistory />} />
          <Route
            path="/worker/plan_investor_details/:id1/:id2"
            element={<WorkerPlanInvestorDetails />}
          />
          <Route path="/workers/investorsDetails/:id1/:id2" element={<WorkerInvestorsDetails />} />
        </Route>

        {/* investor routing */}

        <Route element={<PrivateInvestorRoute />}>
          <Route path="/investor/plans" element={<InvestorPlans />} />
          <Route path="/investor/active-plans" element={<InvestorActivePlans />} />
          <Route path="/investor/withdrawal-requests" element={<WithdrawalRequests />} />
          <Route path="/investor/my-details" element={<MyDetails />} />
          <Route path="/investor/view-plan/:id" element={<InvestorViewPlan />} />
          <Route path="/investor/view-deposits/:id" element={<ViewDeposits />} />
          <Route path="/investor/history/:id" element={<InvestorHistory />} />
          <Route path="/investor/wht-gallery" element={<InvestorWhtGallery />} />
          <Route path="/investor/view-plan-details/:id" element={<ViewPlanDetails />} />
        </Route>
      </Routes>
    </>
  );
}

export default Routing;
