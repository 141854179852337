import React, { useEffect, useState } from 'react';

import Button from '../../../components/Button';
import Input from '../../../components/Input';
import { IoSearchOutline } from 'react-icons/io5';
import { HiOutlineArrowLeft } from 'react-icons/hi';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import Worker_web_protal from '../../../layout/Worker_web_portal';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import Manager_web_portal from '../../../layout/Manager_web_portal';
import Investor_web_portal from '../../../layout/Investor_web_portal';
const InvestorHistory = () => {
  const { id } = useParams();
  const [searchTerm, setSearchTerm] = useState('');
  const cnicId = JSON.parse(localStorage.getItem('investor_data'));
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const [filteredDeposits, setFilteredDeposits] = useState([]);
  const currentPageData = filteredDeposits.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (newPage) => setCurrentPage(newPage);
  const [history, setHistory] = useState([]);
  const [deduction, setDeduction] = useState([]);
  useEffect(() => {
    filterDeposits();

    const params = {
      investorCnic: cnicId?.cnic
    };
    axios
      .post(`https://welcome-habibi-backend.vercel.app/v1/worker/my-history`, params)
      .then((res) => {
        console.log(res, 'dddddddddddddddddddsewwwwwwwwwwww');

        setHistory(res.data);
      })
      .catch((error) => {});
  }, [history, searchTerm]);

  useEffect(() => {
    axios
      .get(
        `https://welcome-habibi-backend.vercel.app/v1/manager/profit-deduction-history/${cnicId.cnic}`
      )
      .then((res) => {
        console.log(res, 'profit-deduction-history');

        setDeduction(res.data);
      })
      .catch((error) => {});
  }, []);

  const filterDeposits = () => {
    let filtered = history?.history || [];

    if (searchTerm.trim()) {
      const lowercasedSearchTerm = searchTerm.toLowerCase();
      filtered = filtered.filter((val) => {
        const investmentIdMatch = val?.name?.toLowerCase().includes(lowercasedSearchTerm);
        const managerIdMatch = val?.cnic?.toLowerCase().includes(lowercasedSearchTerm);

        return investmentIdMatch || managerIdMatch;
      });
    }

    setFilteredDeposits(filtered);
    setCurrentPage(1);
  };

  const [tabHistory, setTabHistory] = useState('deposit');
  return (
    <Investor_web_portal
      language={
        <>
          <p className=" sm:block hidden">English | Urdu</p>
        </>
      }
      ToggleBtn={
        <div className=" flex gap-4 items-center">
          <HiOutlineArrowLeft />
          <h2 className="">Deposits.</h2>
        </div>
      }
      title_props={
        <>
          <div className=" w-96 relative">
            <Input
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder={''}
              className={' border bg-white w-full rounded-full'}
            />

            <Button
              Icon={<IoSearchOutline className=" text-white text-xl" />}
              label={'search'}
              className={
                ' absolute top-0 right-0 bg-secondary  text-white uppercase font-normal rounded-full'
              }
            />
          </div>
        </>
      }>
      <div className="  w-full  mb-4  md:hidden block relative">
        <Input placeholder={''} className={' border bg-white w-full rounded-full'} />

        <Button
          Icon={<IoSearchOutline className=" text-white text-xl" />}
          label={'search'}
          className={
            ' absolute top-0 right-0 bg-secondary text-white uppercase font-normal rounded-full'
          }
        />
      </div>
      <div className=" sm:flex block    justify-between items-center">
        <div>
          <h2 className="sm:block hidden">History</h2>
        </div>
      </div>
      <div className="  flex gap-4 items-center pt-4">
        <button
          onClick={() => setTabHistory('deposit')}
          className={` text-black  font-semibold ${tabHistory === 'deposit' ? 'border-b-2 border-secondary' : ''} `}>
          Deposit History
        </button>
        <button
          onClick={() => setTabHistory('profits')}
          className={` text-black  font-semibold ${tabHistory === 'profits' ? 'border-b-2 border-secondary' : ''} `}>
          Profits & Deduction History
        </button>
      </div>
      {tabHistory === 'deposit' ? (
        <section className="mb-20 mt-7 text-gray-800">
          <div className="block rounded-lg ">
            <div className="flex flex-col">
              <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="overflow-hidden">
                    <table className="min-w-full mb-0">
                      <thead className=" ">
                        <tr className=" rounded-lg whitespace-nowrap ">
                          <th scope="col" className=" text-sm  text-darkGray  font-medium px-6">
                            Date
                          </th>
                          <th scope="col" className="text-sm  text-darkGray   font-medium px-6 ">
                            Investor Cnic
                          </th>
                          <th scope="col" className="text-sm  text-darkGray   font-medium px-6 ">
                            Plan Name
                          </th>
                          <th scope="col" className="text-sm  text-darkGray     font-medium px-6 ">
                            Deposit Type
                          </th>

                          <th scope="col" className=" text-sm text-darkGray    font-medium px-6 ">
                            Amount
                          </th>
                        </tr>
                      </thead>
                      <tbody className="">
                        {currentPageData?.map((item, index) => {
                          return (
                            <>
                              <tr className="bg-white border-t-8 border-b-8    border-[#F6F6F6]  rounded-md ">
                                <td className="align-middle text-sm font-normal px-6 py-4 whitespace-nowrap  text-center">
                                  <span className=" text-base text-black  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                    {moment(item?.createdAt).format('DD-MM-YYYY')}
                                  </span>
                                </td>
                                <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                  <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                    {item?.investorCnic}
                                  </span>
                                </td>
                                <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                  <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                    {item?.planId?.planName}
                                  </span>
                                </td>
                                <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                  <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                    {item?.depositType}
                                  </span>
                                </td>

                                <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                  <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                    {/* {item?.totalInvestment} */}
                                    {item?.amount} Deposit
                                  </span>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className=" flex justify-end items-center  py-4 gap-6">
              <button
                className={`${
                  currentPage === 1 ? 'bg-gray-500  text-white' : 'bg-secondary text-white'
                } flex justify-center items-center  w-10 h-10 rounded-md`}
                disabled={currentPage === 1}
                onClick={() => handlePageChange(currentPage - 1)}>
                <FaAngleLeft size={20} />
              </button>
              <span className="  text-primary  font-semibold">
                {currentPage} of {Math.ceil(filteredDeposits.length / itemsPerPage)}
              </span>
              <button
                className={`${
                  currentPage * itemsPerPage >= filteredDeposits.length
                    ? 'bg-gray-500  text-white'
                    : 'bg-secondary text-white'
                } flex justify-center items-center  w-10 h-10 rounded-md`}
                disabled={currentPage * itemsPerPage >= filteredDeposits.length}
                onClick={() => handlePageChange(currentPage + 1)}>
                <FaAngleRight size={20} />
              </button>
            </div>
          </div>
        </section>
      ) : (
        <section className="mb-20 mt-7 text-gray-800">
          <div className="block rounded-lg ">
            <div className="flex flex-col">
              <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="overflow-hidden">
                    <table className="min-w-full mb-0">
                      <thead className=" ">
                        <tr className=" rounded-lg whitespace-nowrap ">
                          <th scope="col" className=" text-sm  text-darkGray  font-medium px-6">
                            Date
                          </th>
                          <th scope="col" className="text-sm  text-darkGray   font-medium px-6 ">
                            Investor Cnic
                          </th>
                          <th scope="col" className="text-sm  text-darkGray   font-medium px-6 ">
                            Plan Name
                          </th>
                          <th scope="col" className="text-sm  text-darkGray     font-medium px-6 ">
                            Deduction Type
                          </th>

                          <th scope="col" className=" text-sm text-darkGray    font-medium px-6 ">
                            Deduction Amount
                          </th>
                          <th scope="col" className=" text-sm text-darkGray    font-medium px-6 ">
                            Profit Amount
                          </th>
                        </tr>
                      </thead>
                      <tbody className="">
                        {deduction?.data?.map((item, index) => {
                          return (
                            <>
                              <tr className="bg-white border-t-8 border-b-8    border-[#F6F6F6]  rounded-md ">
                                <td className="align-middle text-sm font-normal px-6 py-4 whitespace-nowrap  text-center">
                                  <span className=" text-base text-black  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                    {moment(item?.createdAt).format('DD-MM-YYYY')}
                                  </span>
                                </td>
                                <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                  <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                    {item?.investorCnic}
                                  </span>
                                </td>
                                <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                  <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                    {item?.planName}
                                  </span>
                                </td>
                                <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                  <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                    {item?.deductionType}
                                  </span>
                                </td>

                                <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                  <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                    {item?.deductionAmount}
                                  </span>
                                </td>
                                <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                  <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                    {item?.profitAmount}
                                  </span>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className=" flex justify-end items-center  py-4 gap-6">
              <button
                className={`${
                  currentPage === 1 ? 'bg-gray-500  text-white' : 'bg-secondary text-white'
                } flex justify-center items-center  w-10 h-10 rounded-md`}
                disabled={currentPage === 1}
                onClick={() => handlePageChange(currentPage - 1)}>
                <FaAngleLeft size={20} />
              </button>
              <span className="  text-primary  font-semibold">
                {currentPage} of {Math.ceil(filteredDeposits.length / itemsPerPage)}
              </span>
              <button
                className={`${
                  currentPage * itemsPerPage >= filteredDeposits.length
                    ? 'bg-gray-500  text-white'
                    : 'bg-secondary text-white'
                } flex justify-center items-center  w-10 h-10 rounded-md`}
                disabled={currentPage * itemsPerPage >= filteredDeposits.length}
                onClick={() => handlePageChange(currentPage + 1)}>
                <FaAngleRight size={20} />
              </button>
            </div>
          </div>
        </section>
      )}
    </Investor_web_portal>
  );
};

export default InvestorHistory;
