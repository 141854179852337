import React, { useState } from 'react';
import Button from '../../../components/Button';
import Worker_web_protal from '../../../layout/Worker_web_portal';
import { HiOutlineArrowLeft } from 'react-icons/hi';
import { IoSearchOutline } from 'react-icons/io5';
import Input from '../../../components/Input';
import { useAlleventsQuery } from '../../../store/services/eventService';
import ViewEvents from '../../ViewEvents/ViewEvents';
const InvestorWhtGallery = () => {
  const { data, isFetching } = useAlleventsQuery();
  console.log(data, isFetching);

  const [isModalOpen2, setIsModalOpen2] = useState(false);

  const openModal2 = () => {
    setIsModalOpen2(true);
  };

  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const [view, setView] = useState({});

  return (
    <Worker_web_protal
      language={
        <>
          <p className=" sm:block  hidden">English | Urdu</p>
        </>
      }
      ToggleBtn={
        <div className=" flex gap-4 items-center">
          <HiOutlineArrowLeft />
          <h2 className=""> WHT GALLERY</h2>
        </div>
      }
      title_props={
        <>
          {/* <div className=" w-96 relative">
            <Input
              placeholder={""}
              className={" border bg-white w-full rounded-full"}
            />

            <Button
              Icon={<IoSearchOutline className=" text-white text-xl" />}
              label={"search"}
              className={
                " absolute top-0 right-0 bg-secondary  text-white uppercase font-normal rounded-full"
              }
            />
          </div> */}
          <h1 className="  font-semibold text-xl  text-primary pb-4">
            Welcome Habibi Trading Pakistan
          </h1>
        </>
      }
    >
      <ViewEvents isModalOpen={isModalOpen2} setIsModalOpen={setIsModalOpen2} getData={view} />
      <div className=" container m-auto">
        <div className=" sm:w-[40%] w-full  mx-auto text-center">
          <h1 className=" font-semibold text-4xl"> WHT GALLERY</h1>
          <p className=" py-6">A journey to the Bright Future</p>
        </div>

        <div className="  w-full  mb-4  sm:hidden block relative">
          <Input placeholder={''} className={' border bg-white w-full rounded-full'} />

          <Button
            Icon={<IoSearchOutline className=" text-white text-xl" />}
            label={'search'}
            className={
              ' absolute top-0 right-0 bg-secondary text-white uppercase font-normal rounded-full'
            }
          />
        </div>

        <div className=" my-12   grid  md:grid-cols-2 grid-cols-1 gap-12">
          {data?.events?.map((item, index) => {
            return (
              <div className=" ">
                <p className=" text-primary font-semibold text-xl text-center py-3">
                  {item?.title}
                </p>
                <div className=" relative h-96">
                  <img src={item?.images[0]} className="  w-full h-full  object-cover" alt="" />
                  <div className=" absolute  top-0  bg-[rgba(0,0,0,0.1)]   h-full rounded-md w-full">
                    <div className=" h-full     pb-4 flex justify-end items-end">
                      <Button
                        onClick={() => {
                          openModal2();

                          setView(item);
                        }}
                        label={'Show More'}
                        className={
                          ' bg-secondary    justify-center w-36 mx-auto text-white  py-3.5'
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Worker_web_protal>
  );
};

export default InvestorWhtGallery;
