import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { FiMenu } from 'react-icons/fi';
import { IoNotificationsOff } from 'react-icons/io5';
import { MdClose } from 'react-icons/md';
const WorkerNav = ({ openSidebar, side, closeSidebar, title_props, language, ToggleBtn }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleNotificationClick = () => {
    setModalOpen(!isModalOpen);
  };

  const [myNotification, setMyNotification] = useState([]);
  const cnicId = JSON.parse(localStorage.getItem('worker_data'));
  useEffect(() => {
    axios
      .get(
        `https://welcome-habibi-backend.vercel.app/v1/worker/worker-all-Notification/${cnicId?._id}`
      )
      .then((res) => {
        console.log(res.data.data, 'dfffffffffffffffffffffff');

        setMyNotification(res.data.data.reverse());
      })
      .catch((error) => {});
  }, []);

  return (
    <nav
      className={`fixed  z-20 bg-white top-0 right-0   ${
        side === 'left-0 md:-left-64' ? 'left-0' : 'left-0 md:left-64'
      }`}>
      <div className="   w-full flex justify-between  items-center p-6">
        <div className=" flex items-center gap-4">
          <div onClick={openSidebar} className="  md:hidden  block">
            {ToggleBtn}
          </div>
          <div className="  hidden md:block">{title_props}</div>
        </div>

        <div className=" flex items-center gap-6">
          <div>
            <p>{language}</p>
          </div>
          <div className=" relative">
            <img
              src={require('../../../assets/image/Notification.png')}
              onClick={handleNotificationClick}
              style={{ cursor: 'pointer' }}
            />
            {myNotification?.length > 0 ? (
              <span className="   bg-green w-3 h-3 right-0 rounded-full absolute top-0"></span>
            ) : (
              <span className=" bg-[#FF6A6A] w-3 h-3 right-0 rounded-full absolute top-0"></span>
            )}

            {/* Notification Modal */}
            {isModalOpen && (
              <div className="   absolute right-0 top-12 flex justify-center items-center z-30">
                <div className="bg-white p-4  rounded shadow-lg w-80">
                  <div className=" flex justify-between items-center">
                    <h2 className="text-xl font-semibold mb-2">Notifications</h2>

                    <MdClose
                      className="  cursor-pointer text-gray-500"
                      onClick={() => setModalOpen(false)}
                      size={25}
                    />
                  </div>
                  <ul className="p-0 flex justify-center items-center h-72 overflow-y-auto">
                    <ul className="p-0 flex flex-col justify-start items-start h-72 overflow-y-auto overflow-x-hidden w-full">
                      {myNotification?.length > 0 ? (
                        myNotification?.map((item, index) => {
                          return (
                            <li className="py-2 flex gap-4 w-full" key={index}>
                              <div>
                                <img src={require('../../../assets/image/logo.png')} className="" />
                              </div>
                              <div>
                                <p className="m-0 text-sm">{item?.message}</p>
                                <span className="text-[11px]">
                                  {moment(item?.createdAt).format('DD-MM-YYYY')}
                                </span>
                              </div>
                            </li>
                          );
                        })
                      ) : (
                        <div className="text-center w-full">
                          <IoNotificationsOff
                            size={60}
                            className="text-secondary text-center mx-auto"
                          />
                          <span className="font-semibold">No message yet</span>
                        </div>
                      )}
                    </ul>
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};
export default WorkerNav;
