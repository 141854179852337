import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const categoryService = createApi({
  reducerPath: 'category',
  tagTypes: 'categories',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://beauty-bridge-a647ece2d453.herokuapp.com/category/',
    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const token = reducers?.authReducer?.adminToken;
      console.log(token);
      headers.set('authorization', token ? `Bearer ${token}` : '');
      return headers;
    }
  }),
  endpoints: (builder) => {
    return {
      create: builder.mutation({
        query: (name) => {
          return {
            url: 'create',
            method: 'POST',
            body: name
          };
        },
        invalidatesTags: ['categories']
      }),
      updateCategory: builder.mutation({
        query: (data) => {
          return {
            url: `update/${data.id}`,
            method: 'PUT',
            body: { name: data.name }
          };
        },
        invalidatesTags: ['categories']
      }),
      deleteCategory: builder.mutation({
        query: (id) => {
          return {
            url: `delete/${id}`,
            method: 'DELETE'
          };
        },
        invalidatesTags: ['categories']
      }),

      fetchCategory: builder.query({
        query: (id) => {
          return {
            url: `get/${id}`,
            method: 'GET'
          };
        },
        providesTags: ['categories']
      }),
      allCategories: builder.query({
        query: () => {
          return {
            url: 'getAll',
            method: 'GET'
          };
        }
      })
    };
  }
});
export const {
  useCreateMutation,
  useFetchCategoryQuery,
  useAllCategoriesQuery,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation
} = categoryService;
export default categoryService;
