import React, { useState, useEffect } from 'react';
import Modal from '../../../components/modal';
import { MdClose } from 'react-icons/md';
import { usePDF } from 'react-to-pdf';
import moment from 'moment';
const View_formDetails = ({
  isModalOpen,
  setIsModalOpen,
  closeModal,
  setUsers,
  getData,
  getDeposit,
  enrollment,
  plan
}) => {
  console.log(getData, 'dfadfadfsssssssssssssss');
  console.log(getDeposit);
  console.log(enrollment, 'enrollment ==============>>>>>>>>>>>>s');
  console.log(plan);

  const { toPDF, targetRef } = usePDF({ filename: `${getData?.name}.pdf` });

  return (
    <div>
      <Modal isOpen={isModalOpen} className={'w-[80%]'} onClose={closeModal}>
        <div className="p-7">
          <div className="flex justify-between items-center mb-4">
            <button
              onClick={() => toPDF()}
              className="rounded-md py-2  hover:bg-secondary hover:text-white px-3 text-[#030229B2] bg-white border border-secondary flex items-center"
            >
              Download PDF
            </button>
            <h1 className="text-xl font-semibold"></h1>
            <MdClose
              className="text-gray-500 cursor-pointer"
              onClick={() => setIsModalOpen(false)}
              size={25}
            />
          </div>

          <form ref={targetRef} className="space-y-6">
            <div className=" mx-6">
              <h1 className="text-center text-primary font-semibold text-4xl mb-6">AGREEMENT</h1>

              <div className="grid grid-cols-1 mx-3 gap-1">
                <div className="  flex justify-center">
                  <div className="flex flex-row items-center w-80">
                    <label className="font-medium font-myarial">Name:</label>
                    <p className="border-b border-black w-full pb-2.5 text-sm pl-2  font-myarial">
                      {getData?.name}
                    </p>
                  </div>
                  <div className="flex   flex-row items-center  w-[450px]">
                    <label className="font-medium whitespace-nowrap font-myarial">
                      Father/Husband Name:
                    </label>
                    <p className="border-b border-black w-full pb-2.5 text-sm  pl-2 font-myarial">
                      {getData?.fatherName}
                    </p>
                  </div>
                  <div className="flex flex-row items-center w-60">
                    <label className="font-medium font-myarial">CNIC:</label>
                    <p className="border-b border-black w-full pb-2.5 text-sm  pl-2 font-myarial">
                      {getData?.cnic}
                    </p>
                  </div>
                </div>

                <div className="flex justify-center">
                  <div className="flex flex-row  items-end w-full">
                    <label className=" font-medium   whitespace-nowrap  font-myarial">
                      Permanent Address:
                    </label>
                    <p className="border-b border-black  w-full p-2.5 pl-2 text-sm font-myarial">
                      {' '}
                      {getData?.permanentAddress}{' '}
                    </p>
                  </div>
                </div>

                <div className="flex">
                  <div className="flex flex-row  items-end w-full">
                    <label className=" font-medium   whitespace-nowrap  font-myarial">
                      Residential Address:
                    </label>
                    <p className="border-b border-black w-full p-2.5 pl-2.5 text-sm font-myarial">
                      {' '}
                      {getData?.temporaryAddress}{' '}
                    </p>
                  </div>
                </div>

                <div className="flex justify-between">
                  <div className="flex flex-row  items-end ">
                    <label className=" font-medium   whitespace-nowrap  font-myarial">
                      Date of Birth:
                    </label>
                    <p className="border-b text-center border-black w-36 p-2.5 pl-2.5 text-sm font-myarial">
                      {' '}
                      {moment(getData?.dateOfBirth).format('DD-MM-YYYY')}{' '}
                    </p>
                  </div>
                  <div className="flex flex-row items-end">
                    <label className="font-medium   whitespace-nowrap  font-myarial">
                      Birth Place:
                    </label>
                    <p className="border-b  text-center border-black w-44 text-sm p-2.5 pl-2.5">
                      {getData?.birthPlace}
                    </p>
                  </div>
                  <div className="flex flex-row w-36 items-end">
                    <label className="font-medium   whitespace-nowrap  font-myarial">Cast:</label>
                    <p className="border-b border-black text-sm p-2.5 pl-2.5 w-52 ">
                      {getData?.cast}
                    </p>
                  </div>
                  <div className="flex flex-row  items-end">
                    <label className="font-medium   whitespace-nowrap  font-myarial">
                      Marital Status:
                    </label>
                    <p className="border-b border-black w-44  p-2.5 pl-2.5 text-center font-myarial text-sm">
                      {getData?.maritalStatus}
                    </p>
                  </div>
                </div>

                <div className="flex justify-between">
                  <div className="flex flex-row     items-end">
                    <label className="font-medium   whitespace-nowrap  font-myarial">
                      Business/Job:
                    </label>
                    <p className="border-b border-black  text-sm p-2.5 pl-2.5 w-44">
                      {getData?.jobDetail}
                    </p>
                  </div>
                  <div className="flex flex-row  items-end">
                    <label className="font-medium   whitespace-nowrap  font-myarial">
                      Contact No:
                    </label>
                    <p className="border-b border-black p-2.5  text-center w-56 text-sm font-myarial">
                      {' '}
                      {getData?.phoneNumber}{' '}
                    </p>
                  </div>
                  <div className="flex flex-row  items-end">
                    <label className="font-medium   whitespace-nowrap  font-myarial">
                      WhatsApp No:
                    </label>
                    <p className="border-b text-center p-2.5 pl-2.5 border-black w-56 text-sm font-myarial">
                      {' '}
                      {getData?.whatsappNumber}{' '}
                    </p>
                  </div>
                </div>

                <div className="flex">
                  <div className="flex flex-row items-end ">
                    <label className="font-medium   whitespace-nowrap  font-myarial">
                      Deposited the 1 <sup>st</sup> installment of{' '}
                    </label>
                    <p className="border-b border-black  p-2.5 pl-2.5 text-center text-sm  w-28">
                      {getDeposit[0]?.amount}
                    </p>
                  </div>
                  <div className="flex flex-row items-end">
                    <label className="font-medium   whitespace-nowrap  font-myarial">PKR on:</label>
                    <p className="border-b border-black text-center  p-2.5 pl-2.5 text-[13px] w-24">
                      {getDeposit?.[0]?.depositDate}
                    </p>
                  </div>
                  <div className="flex flex-row items-end w-full">
                    <label className="font-medium   whitespace-nowrap  font-myarial">
                      In the W.H.T, Considering a
                    </label>
                    <p className="border-b text-sm text-center p-2.5  font-myarial pl-2.5 border-black w-20 ">
                      {enrollment?.numberOfYears}
                    </p>
                  </div>
                </div>

                <div className="flex">
                  <div className="flex items-end flex-row w-full">
                    <label className="font-medium   whitespace-nowrap  font-myarial">
                      Year/Years in
                    </label>
                    <p className="border-b border-black w-16 font-myarial font-myarial p-2.5 pl-2.5  text-sm text-center">
                      {' '}
                      {plan?.planCategory === 'A'
                        ? 1
                        : plan?.planCategory === 'C'
                          ? enrollment?.paymentFrequency === 'monthly'
                            ? enrollment?.numberOfYears * 12
                            : enrollment?.numberOfYears * 4
                          : null}
                    </p>
                    <label className="font-medium   whitespace-nowrap  font-myarial">
                      installments of
                    </label>
                    <p className="border-b border-black text-center  p-2.5 pl-2.5  font-myarial text-sm w-28">
                      {plan?.planCategory === 'A'
                        ? getDeposit[0]?.amount
                        : plan?.planCategory === 'C'
                          ? getDeposit[0]?.amount
                          : null}
                    </p>
                    <label className="font-medium   whitespace-nowrap  font-myarial">PKR on</label>
                    <p className="border-b text-center p-2.5 pl-2.5 text-sm border-black w-28">
                      {' '}
                      {plan?.planCategory === 'A'
                        ? 'One Time Investment'
                        : plan?.planCategory === 'C'
                          ? enrollment?.paymentFrequency
                          : null}
                    </p>
                    <label className="font-medium   whitespace-nowrap  font-myarial">Basis.</label>
                  </div>
                </div>
              </div>

              <div className=" mt-3">
                <img src={require('../../../assets/image/ameen.png')} />

                <div className="grid grid-cols-1 gap-1 mx-3">
                  <div className="flex justify-center">
                    <div className="flex flex-row items-center w-80">
                      <label className="font-medium   whitespace-nowrap  font-myarial">Name:</label>
                      <p className="border-b  pl-2 border-black w-60 pb-2.5 text-sm   font-myarial">
                        {' '}
                        {getData?.guardian?.name}{' '}
                      </p>
                    </div>
                    <div className="flex flex-row items-center w-[440px]">
                      <label className="font-medium   whitespace-nowrap  font-myarial">
                        Father/Husband Name:
                      </label>
                      <p className="border-b   text-sm border-black pb-2.5 pl-2.5 w-60 font-myarial">
                        {' '}
                        {getData?.guardian?.fatherName}
                      </p>
                    </div>
                    <div className="flex flex-row items-center w-60">
                      <label className="font-medium   whitespace-nowrap  font-myarial">CNIC:</label>
                      <p className="border-b border-black w-48 pb-2.5   pl-2.5 text-sm font-myarial">
                        {' '}
                        {getData?.guardian?.cnic}{' '}
                      </p>
                    </div>
                  </div>

                  <div className="flex">
                    <div className="flex flex-row items-end w-full">
                      <label className="font-medium   whitespace-nowrap  font-myarial">
                        Permanent Address:
                      </label>
                      <p className="border-b border-black w-full  p-2.5 text-sm font-myarial">
                        {' '}
                        {getData?.guardian?.permanentAddress}{' '}
                      </p>
                    </div>
                  </div>

                  <div className="flex">
                    <div className="flex flex-row items-end w-full">
                      <label className="font-medium   whitespace-nowrap  font-myarial">
                        Residential Address:
                      </label>
                      <p className="border-b border-black w-full p-2.5 text-sm font-myarial">
                        {' '}
                        {getData?.guardian?.permanentAddress}{' '}
                      </p>
                    </div>
                  </div>

                  <div className="flex justify-between">
                    <div className="flex flex-row  items-end ">
                      <label className="font-medium   whitespace-nowrap  font-myarial ">
                        Relationship with the investor:
                      </label>
                      <p className="border-b border-black p-2.5   text-center text-sm">
                        {getData?.guardian?.name}
                      </p>
                    </div>
                    <div className="flex  items-end flex-row ">
                      <label className="font-medium   whitespace-nowrap  font-myarial ">
                        Personal Phone Number:
                      </label>
                      <p className="border-b border-black p-2.5  w-36 text-sm">
                        {getData?.guardian?.phoneNumber}
                      </p>
                    </div>

                    <div className="flex flex-row  items-end ">
                      <label className="font-medium   whitespace-nowrap  font-myarial">
                        WhatsApp Number:
                      </label>
                      <p className="border-b text-sm border-black w-36 p-2.5   pl-2.5 ">
                        {' '}
                        {getData?.guardian?.whatsappNumber}{' '}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <img src={require('../../../assets/image/bio.jpeg')} className=" mt-3 mb-3" alt="" />
              <div className=" flex justify-between mx-3 pb-3 pt-24 items-end">
                <div className=" items-end flex">
                  <label className="  font-myarial">Date:</label>
                  <p className="  m-0 pt-1 w-44 border-b border-black"></p>
                </div>
                <div>
                  <p className=" font-myarial font-medium  p-0">AMIR SHAHZAD</p>
                  <p className=" font-myarial font-medium p-0">Chief Executive Officer</p>
                  <p className=" font-myarial font-medium  p-0">Welcome Habibi Trading</p>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default View_formDetails;
