import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const blogService = createApi({
  reducerPath: 'blog',
  tagTypes: 'blogs',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://beauty-bridge-a647ece2d453.herokuapp.com/blog/',
    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const token = reducers?.authReducer?.adminToken;
      console.log(token);
      headers.set('authorization', token ? `Bearer ${token}` : '');
      return headers;
    }
  }),
  endpoints: (builder) => {
    return {
      create: builder.mutation({
        query: (name) => {
          return {
            url: 'create',
            method: 'POST',
            body: name
          };
        },
        invalidatesTags: ['blogs']
      }),
      updateblog: builder.mutation({
        query: (data) => {
          return {
            url: `update/${data.id}`,
            method: 'PUT',
            body: { name: data.name }
          };
        },
        invalidatesTags: ['blogs']
      }),
      deleteblog: builder.mutation({
        query: (id) => {
          return {
            url: `delete/${id}`,
            method: 'DELETE'
          };
        },
        invalidatesTags: ['blogs']
      }),

      fetchblog: builder.query({
        query: (id) => {
          return {
            url: `get/${id}`,
            method: 'GET'
          };
        },
        providesTags: ['blogs']
      }),
      allblogs: builder.query({
        query: () => {
          return {
            url: 'getAll',
            method: 'GET'
          };
        }
      })
    };
  }
});
export const {
  useCreateMutation,
  useFetchblogQuery,
  useAllblogsQuery,
  useUpdateblogMutation,
  useDeleteblogMutation
} = blogService;
export default blogService;
