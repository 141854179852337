import React, { useEffect, useState } from 'react';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import { FiPlus } from 'react-icons/fi';
import { IoSearchOutline } from 'react-icons/io5';
import { HiOutlineArrowLeft } from 'react-icons/hi';
import moment from 'moment';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import Investor_web_portal from '../../../layout/Investor_web_portal';
import AddRequests from './AddRequests';
import { useAllinvestorsQuery } from '../../../store/services/investorService';
import axios from 'axios';
import ViewSlip from '../../worker/deposits/ViewSlip';
const WithdrawalRequests = () => {
  const [getMyInvestment, setGetMyInvestment] = useState([]);

  const cnicId = JSON.parse(localStorage.getItem('investor_data'));

  useEffect(() => {
    const param = {
      cnic: cnicId?.cnic
    };
    axios
      .post(`https://welcome-habibi-backend.vercel.app/v1/worker/my-investor`, param)
      .then((res) => {
        console.log(res.data, 'dfffffffffffffffffffffff');

        setGetMyInvestment(res.data.data);
      })
      .catch((error) => {});
  }, []);

  const { data, isFetching } = useAllinvestorsQuery();
  console.log(data, isFetching);

  const [users, setUsers] = useState([]);

  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  const [singleUser, setSingleUser] = useState({});
  const UpdateModal = () => {
    setIsUpdateOpen(true);
  };

  const closeUpdateModal = () => {
    setIsUpdateOpen(false);
  };

  console.log(isUpdateOpen);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [getImageData, setGetImageData] = useState({});
  console.log('----->>', getImageData);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [filteredDeposits, setFilteredDeposits] = useState([]);
  const currentPageData = filteredDeposits.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (newPage) => setCurrentPage(newPage);

  useEffect(() => {
    filterDeposits();
  }, [data, searchTerm]);

  const filterDeposits = () => {
    let filtered = getMyInvestment?.slice()?.reverse() || [];

    if (searchTerm.trim()) {
      const lowercasedSearchTerm = searchTerm.toLowerCase();
      filtered = filtered.filter((val) => {
        const investmentIdMatch = val?.investmentId?.toLowerCase().includes(lowercasedSearchTerm);
        const managerIdMatch = val?.managerData?.name?.toLowerCase().includes(lowercasedSearchTerm);
        const statusMatch = val?.status?.toLowerCase().includes(lowercasedSearchTerm);

        return investmentIdMatch || managerIdMatch || statusMatch;
      });
    }

    setFilteredDeposits(filtered);
    setCurrentPage(1);
  };

  return (
    <Investor_web_portal
      language={
        <>
          <p className=" sm:block hidden">English | Urdu</p>
        </>
      }
      ToggleBtn={
        <div className=" flex gap-4 items-center">
          <HiOutlineArrowLeft />
          <h2 className="">My Investment</h2>
        </div>
      }
      title_props={
        <>
          <div className=" w-96 relative">
            <Input
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder={''}
              className={' border bg-white w-full rounded-full'}
            />

            <Button
              Icon={<IoSearchOutline className=" text-white text-xl" />}
              label={'search'}
              className={
                ' absolute top-0 right-0 bg-secondary  text-white uppercase font-normal rounded-full'
              }
            />
          </div>
        </>
      }>
      <ViewSlip
        getData={getImageData}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        setUsers={setUsers}
      />

      <div className=" sm:flex block    justify-between items-center">
        <div>
          <h2 className=" sm:block hidden">My Investment</h2>
        </div>

        {/* <div className=" flex gap-3 items-center">
          <Button
            onClick={openModal}
            Icon={<FiPlus />}
            label={"Add Request"}
            className={
              " bg-secondary text-white  font-normal py-3 rounded-full"
            }
          />

        </div> */}
      </div>
      <section className="mb-20 mt-5 text-gray-800">
        <div className="block rounded-lg ">
          <div className="flex overflow-hidden flex-col">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full sm:px-6 lg:px-8">
                <div className="">
                  <table className="min-w-full mb-0">
                    <thead className=" ">
                      <tr className=" rounded-lg whitespace-nowrap ">
                        <th scope="col" className=" text-sm text-darkGray    font-medium px-6 ">
                          Investment ID
                        </th>
                        <th scope="col" className=" text-sm text-darkGray   font-medium px-6 ">
                          Deposit Date
                        </th>

                        <th scope="col" className="text-sm  text-darkGray   font-medium px-6 ">
                          Deposit Type
                        </th>

                        <th scope="col" className="text-sm  text-darkGray     font-medium px-6 ">
                          Deposit Amount
                        </th>
                        <th scope="col" className="text-sm  text-darkGray     font-medium px-6 ">
                          Profit
                        </th>

                        <th scope="col" className="text-sm  text-darkGray     font-medium px-6 ">
                          Deduction Type
                        </th>

                        <th scope="col" className="text-sm  text-darkGray     font-medium px-6 ">
                          Deduction
                        </th>

                        <th scope="col" className="text-sm  text-darkGray     font-medium px-6 ">
                          Reference
                        </th>

                        <th scope="col" className="text-sm    text-darkGray   font-medium px-6 ">
                          Slip
                        </th>

                        <th
                          scope="col"
                          className="text-sm    text-darkGray   font-medium px-6 "></th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {getMyInvestment?.map((item, index) => {
                        return (
                          <>
                            <tr className="bg-white border-8    border-[#F6F6F6]  rounded-md ">
                              <td className="align-middle text-sm font-normal px-6 py-4 whitespace-nowrap  text-center">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                  {item?._id}
                                </span>
                              </td>
                              <td className="text-sm font-normal text-center px-6 py-4 whitespace-nowrap">
                                <span className=" text-base  text-black py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                  {moment(item?.depositDate).format('DD/MM/YYYY')}
                                </span>
                              </td>
                              <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                <span className=" text-base  text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                  {item?.depositType}
                                </span>
                              </td>
                              <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                <span className=" text-base  text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                  {item?.amount}
                                </span>
                              </td>
                              <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                <span className=" text-base  text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                  {item?.profit}
                                </span>
                              </td>
                              <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                <span className=" text-base  text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                  {item?.profitDeduction?.deduction?.type
                                    ? item?.profitDeduction?.deduction?.type
                                    : '-'}
                                </span>
                              </td>
                              <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                <span className=" text-base  text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                  {item?.deduction}
                                </span>
                              </td>

                              <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                <span className=" text-base  text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                  {item?.name}
                                </span>
                              </td>

                              <td className="align-middle cursor-pointer text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                <span
                                  onClick={() => {
                                    openModal();
                                    setGetImageData(item);
                                  }}
                                  className=" text-base text-secondary  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                  {item?.slip.slice(-6)}
                                </span>
                              </td>

                              {/* <td className="align-middle  text-sm font-normal px-6  whitespace-nowrap">
                              <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                <PiPencilSimpleFill className=" text-primary" />
                              </span>
                            </td> */}
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className=" flex justify-end items-center  py-4 gap-6">
            <button
              className={`${
                currentPage === 1 ? 'bg-gray-500  text-white' : 'bg-secondary text-white'
              } flex justify-center items-center  w-10 h-10 rounded-md`}
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}>
              <FaAngleLeft size={20} />
            </button>
            <span className="  text-primary  font-semibold">
              {currentPage} of {Math.ceil(filteredDeposits.length / itemsPerPage)}
            </span>
            <button
              className={`${
                currentPage * itemsPerPage >= filteredDeposits.length
                  ? 'bg-gray-500  text-white'
                  : 'bg-secondary text-white'
              } flex justify-center items-center  w-10 h-10 rounded-md`}
              disabled={currentPage * itemsPerPage >= filteredDeposits.length}
              onClick={() => handlePageChange(currentPage + 1)}>
              <FaAngleRight size={20} />
            </button>
          </div>
        </div>
      </section>
    </Investor_web_portal>
  );
};

export default WithdrawalRequests;
