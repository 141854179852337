import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Base_url } from '../../../utils/Base_url';
import Swal from 'sweetalert2';
import Input from '../../../components/Input';
import * as XLSX from 'xlsx'; // Importing xlsx library

import Button from '../../../components/Button';
import { FiPlus } from 'react-icons/fi';
import AddCompanyExpense from './AddCompanyExpense';
import Manager_web_portal from '../../../layout/Manager_web_portal';
import { IoSearchOutline } from 'react-icons/io5';
import { HiOutlineArrowLeft } from 'react-icons/hi';
import ViewSlip from './ViewSlip';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import moment from 'moment';
import { json, Link } from 'react-router-dom';
import Datepicker from 'react-datepicker';
const CompanyExpense = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [data, setData] = useState([]);

  useEffect(() => {
    const params = {
      startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : '',
      endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : ''
    };

    axios
      .post('https://welcome-habibi-backend.vercel.app/v1/manager/all-company-expense', params)
      .then((res) => {
        // console.log('Company--->>>', res);
        setData(res.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, [startDate, endDate]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [filteredDeposits, setFilteredDeposits] = useState([]);
  const currentPageData = filteredDeposits.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (newPage) => setCurrentPage(newPage);

  const filterDeposits = () => {
    let filtered = data?.data?.slice() || [];

    if (searchTerm.trim()) {
      const lowercasedSearchTerm = searchTerm.toLowerCase();
      filtered = filtered.filter((val) => {
        return (
          val?.receiptNumber?.toLowerCase().includes(lowercasedSearchTerm) ||
          val?.managerData?.name?.toLowerCase().includes(lowercasedSearchTerm) ||
          val?.status?.toLowerCase().includes(lowercasedSearchTerm)
        );
      });
    }

    setFilteredDeposits(filtered);
  };

  useEffect(() => {
    filterDeposits();
  }, [data, searchTerm]);

  const [getData, setGetData] = useState({});
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [loading, setLoading] = useState(false);

  const handleFilter = () => {
    const payload = {
      ...(selectedMonth && { monthName: selectedMonth }),
      ...(selectedYear && { year: Number(selectedYear) })
    };
    setLoading(true);
    // // Simulate an async operation (e.g., API call)
    // setTimeout(() => {
    //   setLoading(false);
    //   console.log('Filter applied!');
    // }, 2000);

    // console.log('Payload', payload?.monthName);
    // console.log('Payload', payload?.year);

    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    const raw = JSON.stringify({
      monthName: payload?.monthName,
      year: payload?.year
    });

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch('https://welcome-habibi-backend.vercel.app/v1/admin/total-expness', requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let data = JSON.parse(result);
        // console.log('Responce Data', data);
        setCompanyExpense(data);
        setLoading(false);
      })
      .catch((error) => console.error(error));
  };
  // handleFilter();

  const openModal2 = () => {
    setIsModalOpen2(true);
  };
  const ExportCVC = (data) => {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    const raw = JSON.stringify({});

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(
      'https://welcome-habibi-backend.vercel.app/v1/manager/all-company-expense',
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        let data = JSON.parse(result);
        // console.log(data?.data);
        // Get current date
        const date = new Date();
        const month = date.toLocaleString('default', { month: 'long' }); // Get the full month name (e.g., 'January')
        const year = date.getFullYear(); // Get the current year (e.g., '2024')

        // Create the filename with the format Month/Year/Collection.xlsx
        const fileName = `${month}-${year}-Expense.xlsx`;

        const ws = XLSX.utils.json_to_sheet(data?.data); // Convert the array to a worksheet
        const wb = XLSX.utils.book_new(); // Create a new workbook
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1'); // Append the worksheet to the workbook
        XLSX.writeFile(wb, fileName); // Download the file with the specified name
      })
      .catch((error) => console.error(error));
  };

  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const handleDateChange = (date) => {
    setStartDate(date);
    // console.log(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    // console.log(date);
  };

  const [companyExpense, setCompanyExpense] = useState({});

  useEffect(() => {
    axios
      .post(`${Base_url}/v1/admin/total-expness`)
      .then((res) => {
        // console.log('Ligal Data', res.data);

        setCompanyExpense(res.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  return (
    <Manager_web_portal
      language={
        <>
          <p className=" sm:block hidden">English | Urdu</p>
        </>
      }
      ToggleBtn={
        <div className=" flex gap-4 items-center">
          <HiOutlineArrowLeft />
          <h2 className="">Company Expense</h2>
        </div>
      }
      title_props={
        <>
          <div className=" w-96 relative">
            <Input
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder={''}
              className={' border bg-white w-full rounded-full'}
            />

            <Button
              Icon={<IoSearchOutline className=" text-white text-xl" />}
              label={'search'}
              className={
                ' absolute top-0 right-0 bg-secondary text-white uppercase font-normal rounded-full'
              }
            />
          </div>
        </>
      }>
      <AddCompanyExpense
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        // setUsers={setUsers}
      />

      <ViewSlip isModalOpen={isModalOpen2} setIsModalOpen={setIsModalOpen2} getData={getData} />

      <div className="  w-full  mb-4  md:hidden block relative">
        <Input
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder={''}
          className={' border bg-white w-full rounded-full'}
        />

        <Button
          Icon={<IoSearchOutline className=" text-white text-xl" />}
          label={'search'}
          className={
            ' absolute top-0 right-0 bg-secondary text-white uppercase font-normal rounded-full'
          }
        />
      </div>
      <div className=" sm:flex block    justify-between items-center">
        <div>
          <h2 className=" sm:block hidden">Company Expense</h2>
        </div>
        <div>
          <button
            onClick={handleFilter}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '8px',
              backgroundColor: '#00AFEF',
              color: 'white',
              padding: '8px 16px',
              fontSize: '14px',
              border: 'none',
              borderRadius: '10px',
              cursor: loading ? 'not-allowed' : 'pointer',
              transition: 'background-color 0.3s ease'
            }}
            disabled={loading}>
            {loading && (
              <div
                style={{
                  width: '12px',
                  height: '12px',
                  border: '2px solid white',
                  borderTop: '2px solid transparent',
                  borderRadius: '50%',
                  animation: 'spin 1s linear infinite'
                }}></div>
            )}
            {loading ? 'Loading...' : 'Filter'}
            <style>
              {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
            </style>
          </button>
        </div>
      </div>

      <div className=" py-4  w-full grid  grid-cols-1  xl:grid-cols-3 md:grid-cols-2 gap-5">
        {/* Daily Expense */}
        <div className=" w-full">
          <Link className="  bg-[#FF9A3E] p-5 pb-7 rounded-2xl flex  justify-between">
            <div>
              <span className=" text-white text-lg font-medium">Daily Expense</span>
              <div className="  flex items-center gap-3 pt-7">
                <p className="text-4xl text-white  font-bold">
                  {companyExpense?.dailyExpense}
                  <sub className=" text-lg">(PKR)</sub>{' '}
                </p>
              </div>
            </div>
          </Link>
        </div>
        {/* Monthly Expense */}
        <div className="w-full">
          <Link className="bg-[#39593C] p-5 pb-7 rounded-2xl flex justify-between">
            <div className="flex flex-col">
              <div className="flex items-center gap-10">
                <span className="text-white text-lg font-medium">Monthly Expense</span>
                <select
                  className="bg-[#39593C] text-white text-[10px] rounded-md p-1"
                  // onChange={(e) => handleCustomMonth(e.target.value)} // Handle month selection
                  value={selectedMonth}
                  onChange={(e) => setSelectedMonth(e.target.value)}>
                  <option value="">Month</option>
                  <option value="January">January</option>
                  <option value="February">February</option>
                  <option value="March">March</option>
                  <option value="April">April</option>
                  <option value="May">May</option>
                  <option value="June">June</option>
                  <option value="July">July</option>
                  <option value="August">August</option>
                  <option value="September">September</option>
                  <option value="October">October</option>
                  <option value="November">November</option>
                  <option value="December">December</option>
                </select>
              </div>
              <div className="flex items-center gap-3 pt-7">
                <p className="text-4xl text-white font-bold">
                  {companyExpense?.monthlyExpense}
                  <sub className="text-lg">(PKR)</sub>
                </p>
              </div>
            </div>
          </Link>
        </div>

        {/* Yearly Expense */}
        <div className="w-full">
          <Link className="bg-[#EF434E] p-5 pb-7 rounded-2xl flex flex-col sm:flex-row justify-between">
            <div className="flex flex-col">
              <div className="flex flex-wrap items-center gap-20">
                <span className="text-white text-sm sm:text-lg font-medium">Yearly Expense</span>
                <select
                  className="bg-[#EF434E] text-white text-xs sm:text-sm rounded-md p-1"
                  // onChange={(e) => handleCustomYear(e.target.value)} // Handle year selection
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}>
                  <option value="">Year</option>
                  {Array.from({ length: 6 }, (_, i) => 2022 + i).map((year) => (
                    <>
                      <option key={year} value={year}>
                        {year}
                      </option>
                    </>
                  ))}
                </select>
              </div>
              <div className="flex items-center gap-3 pt-5 sm:pt-7">
                <p className="text-2xl sm:text-4xl text-white font-bold">
                  {companyExpense?.yearlyExpense}
                  <sub className="text-sm sm:text-lg">(PKR)</sub>
                </p>
              </div>
            </div>
          </Link>
        </div>
      </div>

      <div className=" flex gap-3 justify-end items-center">
        <div className=" flex gap-4">
          <div className=" relative">
            <Datepicker
              selected={startDate}
              onChange={handleDateChange}
              // minDate={new Date()}

              placeholderText="YYYY/MM/DD"
              className="bg-white w-full  rounded-lg h-12 p-4 "
            />
            <div className=" absolute right-4 top-3">
              <img src={require('../../../assets/image/Calendar.png')} />
            </div>
          </div>
          <div className=" relative">
            <Datepicker
              selected={endDate}
              onChange={handleEndDateChange}
              // minDate={new Date()}
              placeholderText="YYYY/MM/DD"
              className="bg-white w-full  rounded-lg h-12 p-4 "
            />
            <div className=" absolute right-4 top-3">
              <img src={require('../../../assets/image/Calendar.png')} />
            </div>
          </div>
        </div>
        <Button
          onClick={openModal}
          Icon={<FiPlus />}
          label={'Add Expense'}
          className="bg-secondary text-white font-normal py-1 sm:py-2 md:py-2 lg:py-3 px-1 sm:px-4 md:px-5 lg:px-6 rounded-xl text-[10px] sm:text-base md:text-lg lg:text-xl"
        />
        <Button
          onClick={() => ExportCVC(currentPageData)}
          label={'Export Data'}
          className="bg-secondary text-white font-normal py-1 sm:py-2 md:py-2 lg:py-3 px-1 sm:px-4 md:px-5 lg:px-6 rounded-xl text-[10px] sm:text-base md:text-lg lg:text-xl"
        />
      </div>

      <section className="mb-20 mt-7 text-gray-800">
        <div className="block rounded-lg ">
          <div className="flex overflow-x-auto flex-col">
            <div className=" sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full sm:px-6 lg:px-8">
                <div className="overflow-hidden">
                  <table className="min-w-full mb-0">
                    <thead className=" ">
                      <tr className=" rounded-lg whitespace-nowrap ">
                        <th scope="col" className=" text-sm  text-darkGray font-semibold px-6 py-4">
                          Date
                        </th>
                        <th
                          scope="col"
                          className=" text-sm text-darkGray    font-semibold px-6 py-4">
                          Expense Type
                        </th>
                        <th
                          scope="col"
                          className=" text-sm text-darkGray   font-semibold px-6 py-4">
                          Amount
                        </th>

                        <th
                          scope="col"
                          className="text-sm  text-darkGray   font-semibold px-6 py-4">
                          Receipt No
                        </th>

                        <th
                          scope="col"
                          className="text-sm  text-darkGray     font-semibold px-6 py-4">
                          Slip
                        </th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {currentPageData?.map((item, index) => {
                        return (
                          <>
                            <tr className="bg-white border-t-8 border-b-8  border-lightGray rounded-md ">
                              <th
                                scope="row"
                                className="text-sm font-normal px-6 py-4   whitespace-nowrap ">
                                <p className="mb-0.5 font-medium text-black">
                                  {moment(item?.date).format('DD/MM/YYYY')}
                                </p>
                              </th>
                              <td className="align-middle text-sm font-normal px-6 py-4 whitespace-nowrap  text-center">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                  {item?.expenseType}
                                </span>
                              </td>
                              <td className="text-sm font-normal text-center px-6 py-4 whitespace-nowrap">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                  {item?.amount}
                                </span>
                              </td>
                              <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                  {item?.receiptNumber}
                                </span>
                              </td>
                              <td className="align-middle cursor-pointer text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                <span
                                  onClick={() => {
                                    openModal2();
                                    setGetData(item);
                                  }}
                                  className=" text-base  text-secondary  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                  {item?.image.slice(-6)}
                                </span>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className=" flex justify-end items-center  py-4 gap-6">
              <button
                className={`${
                  currentPage === 1 ? 'bg-gray-500  text-white' : 'bg-secondary text-white'
                } flex justify-center items-center  w-10 h-10 rounded-md`}
                disabled={currentPage === 1}
                onClick={() => handlePageChange(currentPage - 1)}>
                <FaAngleLeft size={20} />
              </button>
              <span className="  text-primary  font-semibold">
                {currentPage} of {Math.ceil(filteredDeposits.length / itemsPerPage)}
              </span>
              <button
                className={`${
                  currentPage * itemsPerPage >= filteredDeposits.length
                    ? 'bg-gray-500  text-white'
                    : 'bg-secondary text-white'
                } flex justify-center items-center  w-10 h-10 rounded-md`}
                disabled={currentPage * itemsPerPage >= filteredDeposits.length}
                onClick={() => handlePageChange(currentPage + 1)}>
                <FaAngleRight size={20} />
              </button>
            </div>
          </div>
        </div>
      </section>
    </Manager_web_portal>
  );
};

export default CompanyExpense;
