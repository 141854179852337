import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import Worker_web_protal from '../../../layout/Worker_web_portal';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import { Base_url } from '../../../utils/Base_url';
import { IoSearchOutline } from 'react-icons/io5';
import { HiOutlineArrowLeft } from 'react-icons/hi';
import { useAllrewardsQuery } from '../../../store/services/rewardService';
import { useSelector } from 'react-redux';
import { VscFilter } from 'react-icons/vsc';
import { GoTriangleDown } from 'react-icons/go';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import moment from 'moment';

const Rewards = () => {
  const { workerToken } = useSelector((state) => state.authReducer);
  const { data, isFetching } = useAllrewardsQuery(workerToken);
  console.log(data, isFetching);

  const [searchTerm, setSearchTerm] = useState('');
  const [minValue, setMinValue] = useState('');
  const [maxValue, setMaxValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filteredDeposits, setFilteredDeposits] = useState([]);
  const [filterStatus, setFilterStatus] = useState('all'); // State to hold the selected filter ('all', 'paid', 'unpaid')

  useEffect(() => {
    filterDeposits();
  }, [data, searchTerm, minValue, maxValue, filterStatus]);

  const toggleDropdown = () => setDropdownVisible(!dropdownVisible);

  const filterDeposits = () => {
    let filtered = data?.rewards?.slice().reverse() || [];
    if (searchTerm) {
      filtered = filtered.filter(
        (deposit) =>
          deposit.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          deposit.cnic.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    if (minValue || maxValue) {
      const min = parseFloat(minValue) || Number.MIN_SAFE_INTEGER;
      const max = parseFloat(maxValue) || Number.MAX_SAFE_INTEGER;
      filtered = filtered.filter((deposit) => {
        const amount = parseFloat(deposit.amount);
        return amount >= min && amount <= max;
      });
    }

    // Filter based on the selected status
    if (filterStatus !== 'all') {
      filtered = filtered.filter((deposit) => deposit.status2 === filterStatus);
    }

    setFilteredDeposits(filtered);
    setCurrentPage(1); // Reset to the first page after filtering
  };

  const currentPageData = filteredDeposits.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (newPage) => setCurrentPage(newPage);

  return (
    <Worker_web_protal
      language={
        <>
          <p className="sm:block hidden">English | Urdu</p>
        </>
      }
      ToggleBtn={
        <div className="flex gap-4 items-center">
          <HiOutlineArrowLeft />
          <h2 className="">Rewards</h2>
        </div>
      }
      title_props={
        <>
          <h1 className="font-semibold text-xl text-primary pb-4">Welcome Habibi Trading</h1>
          <div className="flex justify-between items-center">
            <h1 className="text-xl font-semibold">Rewards</h1>
            <select
              value={filterStatus}
              onChange={(e) => setFilterStatus(e.target.value)}
              className="ml-4 bg-white border rounded-md p-2 text-sm"
            >
              <option value="all">All</option>
              <option value="paid">Paid</option>
              <option value="unpaid">Unpaid</option>
            </select>
          </div>
        </>
      }
    >
      <div className="w-full mb-4 sm:hidden block relative">
        <Input
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder={''}
          className={'border bg-white w-full rounded-full'}
        />

        <Button
          Icon={<IoSearchOutline className="text-white text-xl" />}
          label={'search'}
          className={
            'absolute top-0 right-0 bg-secondary text-white uppercase font-normal rounded-full'
          }
        />
      </div>

      <section className="mb-20 mt-7 text-gray-800">
        <div className="block rounded-lg">
          <div className="flex overflow-x-auto flex-col">
            <div className="sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full sm:px-6 lg:px-8">
                <div className="overflow-hidden">
                  <table className="min-w-full mb-0">
                    <thead className="">
                      <tr className="rounded-lg whitespace-nowrap">
                        <th scope="col" className="text-sm text-darkGray font-medium px-6">
                          Date
                        </th>
                        <th scope="col" className="text-sm text-darkGray font-medium px-6">
                          Investment ID
                        </th>
                        <th scope="col" className="text-sm text-darkGray font-medium px-6">
                          Name
                        </th>
                        <th scope="col" className="text-sm text-darkGray font-medium px-6">
                          CNIC No
                        </th>
                        <th scope="col" className="text-sm text-darkGray font-medium px-6">
                          Deposit Amount
                        </th>
                        <th scope="col" className="text-sm text-darkGray font-medium px-6">
                          Reward Amount
                        </th>
                        <th scope="col" className="text-sm text-darkGray font-medium px-6">
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {currentPageData?.map((item, index) => {
                        if (item?.status === 'approved') {
                          return (
                            <tr className="bg-white border-t-8 border-b-8 border-[#F6F6F6] rounded-md">
                              <th
                                scope="row"
                                className="text-sm font-normal px-6 py-4 whitespace-nowrap"
                              >
                                <p className="mb-0.5 font-medium text-black">
                                  {moment(item?.createdAt).format('DD/MM/YYYY')}
                                </p>
                              </th>
                              <th
                                scope="row"
                                className="text-sm font-normal px-6 py-4 whitespace-nowrap"
                              >
                                <p className="mb-0.5 font-medium text-black">{item?.investment}</p>
                              </th>
                              <td className="align-middle text-sm font-normal px-6 py-4 whitespace-nowrap text-center">
                                <span className="text-base text-black py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline bg-green-200 rounded-full">
                                  {item?.name}
                                </span>
                              </td>
                              <td className="text-sm font-normal text-center px-6 py-4 whitespace-nowrap">
                                <span className="text-base text-black py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline bg-green-200 rounded-full">
                                  {item?.cnic}
                                </span>
                              </td>
                              <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                <span className="text-base text-black py-1 px-2.5 leading-none whitespace-nowrap bg-green-200 rounded-full">
                                  {item?.depositAmount}
                                </span>
                              </td>
                              <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                <span className="text-base text-black py-1 px-2.5 leading-none whitespace-nowrap bg-green-200 rounded-full">
                                  {item?.amount}
                                </span>
                              </td>

                              <td className="align-middle text-sm font-normal px-6 whitespace-nowrap">
                                <span className="text-base text-black px-2.5 leading-none whitespace-nowrap bg-green-200 rounded-full">
                                  {item?.status2 === 'paid' ? (
                                    <span className="text-base text-green bg-lightGray py-2 cursor-not-allowed px-3 leading-none flex justify-center whitespace-nowrap bg-green-200 rounded-full">
                                      Paid
                                    </span>
                                  ) : (
                                    <span className="text-base text-[#EF434E] bg-[#fdeced] cursor-not-allowed py-2 px-3 leading-none whitespace-nowrap bg-green-200 rounded-full">
                                      Unpaid
                                    </span>
                                  )}
                                </span>
                              </td>
                            </tr>
                          );
                        }
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end items-center py-4 gap-6">
            <button
              className={`${
                currentPage === 1 ? 'bg-gray-500 text-white' : 'bg-secondary text-white'
              } flex justify-center items-center w-10 h-10 rounded-md`}
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              <FaAngleLeft />
            </button>
            <div className="text-black text-sm">
              <span className="font-bold">Page {currentPage}</span> of{' '}
              {Math.ceil(filteredDeposits.length / itemsPerPage)}
            </div>
            <button
              className={`${
                currentPage === Math.ceil(filteredDeposits.length / itemsPerPage)
                  ? 'bg-gray-500 text-white'
                  : 'bg-secondary text-white'
              } flex justify-center items-center w-10 h-10 rounded-md`}
              disabled={currentPage === Math.ceil(filteredDeposits.length / itemsPerPage)}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              <FaAngleRight />
            </button>
          </div>
        </div>
      </section>
    </Worker_web_protal>
  );
};

export default Rewards;
