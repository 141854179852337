import { Navigate } from 'react-router-dom';

const LoginRedirectRoute = ({ children }) => {
  const adminToken = localStorage.getItem('adminToken');
  const managerToken = localStorage.getItem('managerToken');
  const workerToken = localStorage.getItem('workerToken');
  const investorToken = localStorage.getItem('investorToken');

  if (adminToken) {
    return <Navigate to="/super_admin/dashboard" />;
  }

  if (managerToken) {
    return <Navigate to="/manager/dashboard" />;
  }

  if (workerToken) {
    return <Navigate to="/workers/my_portal" />;
  }

  if (investorToken) {
    return <Navigate to="/investor/plans" />;
  }

  return children;
};

export default LoginRedirectRoute;
