import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Base_url } from '../../../utils/Base_url';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import { IoSearchOutline } from 'react-icons/io5';
import { HiOutlineArrowLeft } from 'react-icons/hi';
import { useAllrequestsQuery } from '../../../store/services/requestService';
import moment from 'moment';
import Super_admin_web_protal from '../../../layout/Super_admin_web_portal';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
const WithdrawalRequests = () => {
  const { data, isFetching } = useAllrequestsQuery();
  console.log(data, isFetching);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [filteredDeposits, setFilteredDeposits] = useState([]);
  const currentPageData = filteredDeposits.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (newPage) => setCurrentPage(newPage);

  useEffect(() => {
    filterDeposits();
  }, [data, searchTerm]);

  const filterDeposits = () => {
    let filtered = data?.data.slice().reverse() || [];

    if (searchTerm.trim()) {
      const lowercasedSearchTerm = searchTerm.toLowerCase();
      filtered = filtered.filter((val) => {
        const investmentIdMatch = val?.investmentId?.toLowerCase().includes(lowercasedSearchTerm);
        const managerIdMatch = val?.managerData?.name?.toLowerCase().includes(lowercasedSearchTerm);
        const statusMatch = val?.status?.toLowerCase().includes(lowercasedSearchTerm);

        return investmentIdMatch || managerIdMatch || statusMatch;
      });
    }

    setFilteredDeposits(filtered);
    setCurrentPage(1);
  };

  const handleStatusChange = async (workerId, newStatus) => {
    console.log(workerId, newStatus);
    const params = {
      status: newStatus,
      requestId: workerId
    };
    axios
      .post(`${Base_url}/v1/admin/update-manager-request`, params)
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });

    axios.post();
  };

  return (
    <section className="mb-20 mt-5 text-gray-800">
      <div className="block rounded-lg ">
        <div className="flex flex-col overflow-hidden">
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full sm:px-6 lg:px-8">
              <div className="">
                <table className="min-w-full mb-0">
                  <thead className=" ">
                    <tr className=" rounded-lg whitespace-nowrap ">
                      <th scope="col" className=" text-sm  text-darkGray font-semibold px-6 py-4">
                        Date
                      </th>
                      <th scope="col" className=" text-sm text-darkGray    font-semibold px-6 py-4">
                        Investment ID
                      </th>

                      <th scope="col" className=" text-sm text-darkGray   font-semibold px-6 py-4">
                        Request Description
                      </th>

                      <th scope="col" className="text-sm  text-darkGray   font-semibold px-6 py-4">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {currentPageData?.map((item, index) => {
                      return (
                        <>
                          <tr className="bg-white border-t-8  border-b-8 border-lightGray  rounded-md ">
                            <th
                              scope="row"
                              className="text-sm font-normal px-6 py-4   whitespace-nowrap "
                            >
                              <p className="mb-0.5 font-medium text-black">
                                {moment(item?.createdAt).format('DD/MM/YYYY')}{' '}
                              </p>
                            </th>
                            <td className="align-middle text-sm font-normal px-6 py-4 whitespace-nowrap  text-center">
                              <span className=" text-base text-black  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                {item?.investmentId}
                              </span>
                            </td>

                            <td className="text-sm font-normal text-center px-6 py-4 whitespace-nowrap">
                              <span className=" text-base text-black  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                {item?.details}
                              </span>
                            </td>
                            <td className="align-middle  text-sm font-normal px-6 py-2 whitespace-nowrap">
                              <select
                                defaultValue={item.status}
                                onChange={(e) => handleStatusChange(item._id, e.target.value)}
                                className="text-base  text-green bg-lightGray  cursor-pointer text-black  py-2 px-3 leading-none  whitespace-nowrap    bg-green-200  rounded-full"
                              >
                                <option value={'pending'}>pending</option>
                                <option value={'approved'}>resolved</option>
                              </select>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className=" flex justify-end items-center  py-4 gap-6">
          <button
            className={`${
              currentPage === 1 ? 'bg-gray-500  text-white' : 'bg-secondary text-white'
            } flex justify-center items-center  w-10 h-10 rounded-md`}
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            <FaAngleLeft size={20} />
          </button>
          <span className="  text-primary  font-semibold">
            {currentPage} of {Math.ceil(filteredDeposits.length / itemsPerPage)}
          </span>
          <button
            className={`${
              currentPage * itemsPerPage >= filteredDeposits.length
                ? 'bg-gray-500  text-white'
                : 'bg-secondary text-white'
            } flex justify-center items-center  w-10 h-10 rounded-md`}
            disabled={currentPage * itemsPerPage >= filteredDeposits.length}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            <FaAngleRight size={20} />
          </button>
        </div>
      </div>
    </section>
  );
};

export default WithdrawalRequests;
