import React from 'react';

const Button = ({ type, label, disabled, className, Icon, Icons, onClick }) => {
  return (
    <button
      onClick={onClick}
      type={type}
      className={`  flex items-center gap-2 bg-[#19173E] sm:px-5 px-3    py-2.5 text-center   font-medium ${className}`}
      disabled={disabled}
    >
      {' '}
      {Icon} {label} {Icons}{' '}
    </button>
  );
};

export default Button;
