import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
const Sidebar = ({ side, closeSidebar }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const navigate = useNavigate();

  const location = useLocation();
  const isActive = (route) => location.pathname.includes(route);

  const handleNavigate = (path, sectionId) => {
    navigate(path);
    setTimeout(() => {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }, 0);
  };

  return (
    <div
      className={`fixed   md:relative top-0  shadow-xl ${side} sm:left-0    w-64 h-screen     z-30 transition-all`}
    >
      <div className="bg-primary   h-full w-64">
        <i
          className="bi bi-x-lg absolute text-black top-4 right-4 sm:hidden block cursor-pointer text-lg"
          onClick={closeSidebar}
        ></i>
        <div className="  px-5 py-8 overflow-y-auto">
          <h1 className=" text-white text-4xl font-medium">
            <img src={require('../../assets/image/logo.png')} className="   mx-auto" alt="" />
            {/* Logo */}
          </h1>
        </div>

        <ul className="">
          <li
            className={`px-4 ${
              isActive('/') ? ' text-red' : 'text-white'
            } cursor-pointer  transition-all py-3  flex items-center  hover:bg-primary `}
          >
            <i className="bi bi-grid-fill  mr-2 inline-block text-xl"></i>
            <Link to="/" className="text-lg capitalize">
              Home
            </Link>
          </li>

          <li
            onClick={() => handleNavigate('/', 'gallerySection')}
            className="px-4 cursor-pointer  transition-all py-3 text-white flex items-center  hover:bg-primary hover:text-white"
          >
            <i className="bi bi-grid-fill  mr-2 inline-block text-xl"></i>
            <Link className="text-lg capitalize">WHT Gallery</Link>
          </li>

          <li
            onClick={() => handleNavigate('/', 'contactUsSection')}
            className="px-4 cursor-pointer  transition-all py-3 text-white flex items-center  hover:bg-primary hover:text-white"
          >
            <i className="bi bi-grid-fill  mr-2 inline-block text-xl"></i>
            <Link className="text-lg capitalize">Contact Us</Link>
          </li>
        </ul>
      </div>
      <div
        onClick={closeSidebar}
        className={` bg-[rgba(0,0,0,0.6)]  ${
          side === '-left-64 md:left-0' ? ' w-64' : ' w-full'
        }     -z-20 md:h-auto h-screen  fixed   top-0   sm:left-0`}
      ></div>
    </div>
  );
};
export default Sidebar;
