import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const uploadService = createApi({
  reducerPath: 'upload',
  tagTypes: 'uploads',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://file-upload-ashen.vercel.app/api/'
  }),
  endpoints: (builder) => {
    return {
      uploadImage: builder.mutation({
        query: (imageFile) => {
          console.log(imageFile);
          const formData = new FormData();
          formData.append('images', imageFile);
          return {
            url: 'upload',
            method: 'POST',
            body: formData
          };
        },
        transformResponse: (response, meta, arg) => {
          return response.data;
        }
      })
    };
  }
});
export const { useUploadImageMutation } = uploadService;
export default uploadService;
