import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Base_url } from '../../../utils/Base_url';
import axios from 'axios';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import Modal from '../../../components/modal';
import { MdClose } from 'react-icons/md';

const Add_Rewards = ({ isModalOpen, setIsModalOpen, closeModal, setUsers }) => {
  const bannerSubmit = async (values) => {
    if (values.categoryId.value.length === 0) {
      toast.error('Please select  category name!');
    } else if (values.name.value.length === 0) {
      toast.error('Please enter name!');
    } else {
      const params = {
        categoryId: values.categoryId.value,
        name: values.name.value
      };
      await axios
        .post(`${Base_url}/subcategory/create`, params)
        .then((res) => {
          console.log(res);

          if (res.data.success === true) {
            toast.success('Category Add Successfully!');
            setIsModalOpen(false);
            axios
              .get(`${Base_url}/subcategory/getAll`)
              .then((res) => {
                console.log(res.data);

                setUsers(res.data.data);
              })
              .catch((error) => {
                console.log(error);
              });
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  };

  const [category, setCategory] = useState([]);

  useEffect(() => {
    axios
      .get(`${Base_url}/category/getAll`)
      .then((res) => {
        console.log(res.data);

        setCategory(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      <Modal className={' md:w-[40%] w-[100%]'} isOpen={isModalOpen} onClose={closeModal}>
        <div className="">
          <div className=" p-5">
            <div className="  flex justify-between items-center">
              <div></div>

              <MdClose
                className="  text-gray-500"
                onClick={() => setIsModalOpen(false)}
                size={25}
              />
            </div>

            <form
              onSubmit={(e) => {
                e.preventDefault();
                bannerSubmit(e.target);
              }}
            >
              <div className=" p-5 flex gap-3 flex-wrap">
                <div className=" w-full">
                  <Input
                    label={'Name'}
                    placeholder={'Enter Name'}
                    name={'name'}
                    className={' w-full  py-4'}
                  />
                </div>

                <div className=" w-full">
                  <Input
                    label={'CNIC No'}
                    placeholder={'Enter CNIC No'}
                    name={''}
                    className={' w-full  py-4'}
                  />
                </div>

                <div className=" w-full">
                  <Input
                    Icon={'PKR'}
                    label={'Amount'}
                    placeholder={'40,000 PKR'}
                    name={'name'}
                    className={' w-full  py-4'}
                  />
                </div>

                <div className=" w-full">
                  <Input
                    label={'Remarks'}
                    placeholder={'Write Anything'}
                    name={'name'}
                    className={' w-full  py-4'}
                  />
                </div>
                <Button
                  label={'Add'}
                  type={'submit'}
                  className={
                    '     bg-blue text-center mt-3 justify-center uppercase text-white py-3 border-b-black  border-2 w-full'
                  }
                />
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Add_Rewards;
