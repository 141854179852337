import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="  bg-Footer bg-center  bg-cover pt-16">
      <div className=" container  p-5 mx-auto">
        <div className="  md:flex block   justify-between items-end pb-8">
          <div className="">
            <img src={require('../../assets/image/logo.png')} className=" w-28" alt="" />
            <h1 className=" font-myfont text-white md:text-6xl text-5xl pt-3">
              Welcome Habibi Trading
            </h1>
            <p className=" text-white pt-6">
              Lorem ipsum dolor sit amet, consectetur adipiscing dolor sit amet, consectetur
              adipiscing
            </p>
            <ul className="flex pt-6 gap-3">
              <li>
                <a href="https://wa.me/923124862727" target="_blank" rel="noopener noreferrer">
                  <img
                    src={require('../../assets/image/WhatsApp_icon.png')}
                    className="w-12"
                    alt="WhatsApp"
                  />
                </a>
              </li>

              <li>
                <a href="tel:+923124862727">
                  <img src={require('../../assets/image/call.png')} className="w-12" alt="Call" />
                </a>
              </li>
              <li>
                <a href="mailto:welcomehabibitrading@gmail.com" target="_blank" rel="noreferrer">
                  <img
                    src={require('../../assets/image/email.webp')}
                    className="w-12"
                    alt="Email"
                  />
                </a>
              </li>

              <li>
                <Link
                  to="https://www.facebook.com/profile.php?id=61564993693678"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={require('../../assets/image/facebook.webp')}
                    className="w-12"
                    alt="Facebook"
                  />
                </Link>
              </li>
            </ul>
          </div>
          <div className=" ">
            <ul className=" leading-10">
              <li>
                <Link to={''} className=" text-lg font-light text-white">
                  WHT Alerts
                </Link>
              </li>
              <li>
                <Link to={''} className=" text-lg font-light text-white">
                  {' '}
                  WHT Gallery
                </Link>
              </li>
              <li>
                <Link to={''} className=" text-lg font-light text-white">
                  {' '}
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className=" py-4 text-center">
          <p className=" m-0 text-white font-light">
            © 2024 Welcome Habibi Trading . All rights reserved | Designed by Lorem ipsum
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
