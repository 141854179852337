import React, { useState, useEffect, useRef, useCallback } from 'react';
import { toast } from 'react-toastify';
import { Base_url } from '../../../utils/Base_url';
import axios from 'axios';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import Modal from '../../../components/modal';
import { MdClose, MdOutlineFileUpload } from 'react-icons/md';
import { useForm } from '../../../hooks/Form';
import { useUploadImageMutation } from '../../../store/services/uploadService';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSuccess } from '../../../store/reducers/globalReducer';
import { useCreateMutation } from '../../../store/services/investorService';
import Add_ViewForm from '../deposits/Add_ViewForm';

const Register_Investor = ({ isModalOpen, setIsModalOpen, closeModal, setUsers }) => {
  const [loading, setLaoder] = useState(false);
  const { workerToken } = useSelector((state) => state.authReducer);
  const [selectedImage, setSelectedImage] = useState(null);

  const [selectedImages, setSelectedImages] = useState(null);

  const fileInputRef = useRef(null);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
  }, []);

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    const file = processFile(e.dataTransfer.files[0]);
    // uploadImage(file);
    setSelectedImages(file);
  }, []);

  const handleFileChange = (e) => {
    processFile(e.target.files[0]);
    // uploadImage(e.target.files[0]);
    setSelectedImages(e.target.files[0]);
  };

  const processFile = (file) => {
    // uploadImage(file);
    setSelectedImages(file);
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const { state, onChange } = useForm({
    name: '',
    email: '',
    password: '',
    phone: '',
    cnic: '',
    phone: ''
  });

  const [create, response] = useCreateMutation();

  const [isUpdateOpen2, setIsUpdateOpen2] = useState(false);

  const UpdateModal2 = () => {
    setIsUpdateOpen2(true);
  };

  const closeUpdateModal2 = () => {
    setIsUpdateOpen2(false);
  };

  console.log(isUpdateOpen2);

  const [viewData, setViewData] = useState({});

  const onSubmit = async (e) => {
    const isPatternCorrect = /^\d{13}$/.test(state.cnic.replace(/[-\s]/g, ''));
    e.preventDefault();

    if (state.name.length === 0) {
      toast.error('Must enter name');
    } else if (!/^92\d{10}$/.test(state.email)) {
      toast.error('Must enter a valid mobile number starting with 92');
    } else if (state.cnic.length === 0) {
      toast.error('Must enter cnic');
    } else if (!isPatternCorrect) {
      toast.error("Please make sure it's exactly 13 digits.");
    } else if (state.password.length === 0) {
      toast.error('Must enter password');
    } else if (!selectedImage) {
      toast.error('Please upload your CNIC');
    } else {
      setLaoder(true);
      let profilephoto = ' ';

      try {
        let param = new FormData();

        param.append('images', selectedImages);

        profilephoto = await axios.post(`https://file-upload-ashen.vercel.app/api/upload`, param);

        console.log(profilephoto.data.data[0], '=====profile photo===');

        create({
          workerId: workerToken,
          fullName: state.name,
          email: state.email,
          contactInfo: state.email,
          password: state.password,
          cnic: state.cnic.replace(/[-\s]/g, ''),
          phone: state.phone,
          images: profilephoto.data.data[0]
        });
      } catch (error) {
        console.log(error);
      }
    }
  };
  useEffect(() => {
    if (response.isError) {
      // setErrors(response?.error?.data?.message);
      toast.error(response?.error?.data?.message);
      setLaoder(false);
    }
  }, [response?.error?.data]);
  console.log(response);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (response.isSuccess) {
      if (response?.data?.success === true) {
        dispatch(setSuccess(response?.data?.message));
        toast.success(response?.data?.message);
        setLaoder(false);
        // window.location.reload()

        setViewData(response?.data?.data);
        setIsModalOpen(false);
        setIsUpdateOpen2(true);
        // console.log(response?.data?.data?.fullName, '=========================');
        // console.log('Registration Done Successfully');
        const workerData = JSON.parse(localStorage.getItem('worker_data'));
        // console.log('==================', workerData?.name);
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');

        const raw = JSON.stringify({
          userType: ['manager', 'admin'],
          message: `${workerData?.name} has successfully registered a new investor, ${response?.data?.data?.fullName}.`
        });

        const requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };

        fetch(
          'https://welcome-habibi-backend.vercel.app/v1/manager/general-notification',
          requestOptions
        )
          .then((response) => response.text())
          .then((result) => console.log(result))
          .catch((error) => console.error(error));
      } else {
        toast.success(response?.data?.message);
        setLaoder(false);
      }
    }
  }, [response.isSuccess]);

  return (
    <div>
      <Add_ViewForm
        isModalOpen={isUpdateOpen2}
        setIsModalOpen={setIsUpdateOpen2}
        getData={viewData}
      />

      <Modal className={' md:w-[40%] w-[100%]'} isOpen={isModalOpen} onClose={closeModal}>
        <div className="">
          <div className=" p-5">
            <div className="  flex justify-between items-center">
              <div></div>

              <MdClose
                className="  text-gray-500"
                onClick={() => setIsModalOpen(false)}
                size={25}
              />
            </div>

            <form onSubmit={onSubmit}>
              <div className=" flex gap-3 p-5 flex-wrap">
                <div className=" w-full">
                  <Input
                    value={state.email}
                    onChange={onChange}
                    label={'Mobile Number'}
                    placeholder={'+92300000000'}
                    name={'email'}
                    type="text"
                    className={' w-full  py-4'}
                  />
                </div>
                <div className=" w-full">
                  <Input
                    value={state.name}
                    onChange={onChange}
                    label={'Full Name'}
                    placeholder={'Enter Name...'}
                    name={'name'}
                    className={' w-full  py-4'}
                  />
                </div>

                <div className=" w-full">
                  <Input
                    onChange={onChange}
                    value={state.cnic}
                    label={'Enter CNIC No'}
                    placeholder={'Enter CNIC...'}
                    name={'cnic'}
                    className={' w-full  py-4'}
                  />
                </div>

                <div className=" w-full">
                  <Input
                    onChange={onChange}
                    value={state.password}
                    label={'Password'}
                    placeholder={'Enter Password...'}
                    name={'password'}
                    className={' w-full  py-4'}
                  />
                </div>

                <div
                  className="w-full border-dashed border-[#9E9E9E] border-2 rounded-lg p-4 cursor-pointer"
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                  onClick={triggerFileInput} // Allow clicking anywhere in the div to trigger file input
                >
                  <div className="bg-[#F6F6F6] mx-auto w-14 flex justify-center items-center h-14 rounded-2xl">
                    <MdOutlineFileUpload size={30} color="#c8c7cf" />
                  </div>
                  <p className="text-primary font-medium mt-3 text-center">
                    <span className="text-secondary">Click here</span> or drag and drop to upload a
                    slip
                  </p>
                  {selectedImage && (
                    <img
                      src={selectedImage}
                      alt="Uploaded slip"
                      className="mt-4 mx-auto"
                      style={{ maxWidth: '100%', height: 'auto' }}
                    />
                  )}
                  <input
                    type="file"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                    ref={fileInputRef}
                  />
                </div>

                {loading === true ? (
                  <button
                    disabled
                    type="button"
                    className="  bg-blue text-center  mt-3 justify-center uppercase text-white py-3 border-b-black  border-2 w-full">
                    <svg
                      aria-hidden="true"
                      role="status"
                      className="inline w-4 h-4 me-3 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                    Loading...
                  </button>
                ) : (
                  <Button
                    label={'Register'}
                    type={'submit'}
                    className={
                      '       bg-blue text-center  mt-3 justify-center uppercase text-white py-3 border-b-black  border-2 w-full'
                    }
                  />
                )}
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Register_Investor;
