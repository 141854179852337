import React, { useEffect, useState } from 'react';
import Worker_web_protal from '../../../layout/Worker_web_portal';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import { IoSearchOutline } from 'react-icons/io5';
import { useTodaydepositsQuery, useAlldepositsQuery } from '../../../store/services/depositService';
import { useSelector } from 'react-redux';
import { useFetchrewardQuery } from '../../../store/services/rewardService';
const My_portal = () => {
  const { workerToken } = useSelector((state) => state.authReducer);

  const { data: todayData, isFetching: isFetchingToday } = useTodaydepositsQuery(workerToken);
  const { data: allData, isFetching: isFetchingAll } = useAlldepositsQuery(workerToken);

  console.log(todayData, isFetchingToday);
  console.log(allData, isFetchingAll);

  const { data: isAllReward, isFetching } = useFetchrewardQuery(workerToken);
  console.log(isAllReward, isFetching, 'dfsaaaaaaaa');

  return (
    <Worker_web_protal
      language={
        <>
          <p>English | Urdu</p>
        </>
      }
      title_props={
        <>
          <h1 className="main_title">Welcome Habibi Trading </h1>
        </>
      }
      ToggleBtn={<img src={require('../../assets/../../assets/image/menu.png')} alt="" />}
    >
      <div className="  w-full  mb-4  md:hidden block relative">
        <Input placeholder={''} className={' border bg-white w-full rounded-full'} />

        <Button
          Icon={<IoSearchOutline className=" text-white text-xl" />}
          label={'search'}
          className={
            ' absolute top-0 right-0 bg-secondary text-white uppercase font-normal rounded-full'
          }
        />
      </div>

      <h2 className="">Statistics</h2>
      <section>
        <div className=" py-4  w-full grid  grid-cols-1  xl:grid-cols-3 md:grid-cols-2 gap-5">
          <div className=" w-full">
            <div className="  bg-[#F44771] p-5 pb-7 rounded-2xl flex  justify-between">
              <div>
                <span className=" text-white text-lg font-medium">Total Deposits</span>
                <div className="  flex items-center gap-3 pt-9">
                  <p className="sm:text-3xl text-2xl text-white  font-semibold">
                    {allData?.totalDeposit} PKR
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className=" w-full">
            <div className="  bg-[#FF9A3E] p-5 pb-7 rounded-2xl flex  justify-between">
              <div>
                <span className=" text-white text-lg font-medium">Total Reward</span>
                <div className="  flex items-center gap-3 pt-9">
                  <p className="sm:text-3xl text-2xl text-white  font-semibold">
                    {isAllReward?.totalRewardsAmount} PKR
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className=" w-full">
            <div className="  bg-[#332A7C] p-5 pb-7 rounded-2xl flex  justify-between">
              <div>
                <span className=" text-white text-lg font-medium">Today's Deposits</span>
                <div className="  flex items-center gap-3 pt-9">
                  <p className="sm:text-3xl text-2xl text-white  font-semibold">
                    {todayData?.todayTotal} PKR
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Worker_web_protal>
  );
};

export default My_portal;
