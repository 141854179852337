import React, { useEffect, useState } from 'react';
import Button from '../../../components/Button';
import { HiDotsVertical, HiOutlineArrowLeft } from 'react-icons/hi';
import { IoSearchOutline } from 'react-icons/io5';
import Input from '../../../components/Input';
import { useAllplansQuery } from '../../../store/services/planService';
import Investor_web_portal from '../../../layout/Investor_web_portal';
import axios from 'axios';
import ViewUserSlip from './ViewUserSlip';
import ForgetPassword from '../../forget_password';

const MyDetails = () => {
  const { data, isFetching } = useAllplansQuery();
  console.log(data, isFetching, '=========>>>>>>');

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [isModalOpen2, setIsModalOpen2] = useState(false);

  const openModal2 = () => {
    setIsModalOpen2(true);
  };

  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const [myDetails, setMyDetails] = useState({});
  const cnicId = JSON.parse(localStorage.getItem('investor_data'));
  const [view, setView] = useState({});
  useEffect(() => {
    axios
      .get(`https://welcome-habibi-backend.vercel.app/v1/worker/investor-profile/${cnicId?.cnic}`)
      .then((res) => {
        console.log(res.data, 'dfffffffffffffffffffffff');

        setMyDetails(res.data.profile);
      })
      .catch((error) => {});
  }, []);

  return (
    <Investor_web_portal
      language={
        <>
          <p className=" sm:block  hidden">English | Urdu</p>
        </>
      }
      ToggleBtn={
        <div className=" flex gap-4 items-center">
          <HiOutlineArrowLeft />
          <h2 className=""> New Plans</h2>
        </div>
      }
      title_props={
        <>
          <div className=" w-96 relative">
            <Input placeholder={''} className={' border bg-white w-full rounded-full'} />

            <Button
              Icon={<IoSearchOutline className=" text-white text-xl" />}
              label={'search'}
              className={
                ' absolute top-0 right-0 bg-secondary  text-white uppercase font-normal rounded-full'
              }
            />
          </div>
        </>
      }>
      <ViewUserSlip isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} getData={myDetails} />

      <ForgetPassword
        isModalOpen={isModalOpen2}
        setIsModalOpen={setIsModalOpen2}
        getData={myDetails}
      />

      <div className=" container m-auto pt-6">
        <div className=" flex justify-between gap-3 items-center">
          <h1 className=" font-semibold text-xl">My Details</h1>
          <button
            onClick={openModal2}
            className=" flex gap-2 border py-2.5 px-3 rounded-lg  text-md border-secondary">
            {' '}
            <div>
              <img src={require('../../../assets/image/Key.png')} />
            </div>{' '}
            Reset Password
          </button>
        </div>

        <div className=" my-12   grid  md:grid-cols-2 grid-cols-1 gap-8">
          <div className=" ">
            <Input
              placeholder={''}
              className={' bg-white w-full  p-3'}
              label={'Email Address'}
              value={myDetails?.contactInfo}
            />
          </div>

          <div className=" ">
            <Input
              placeholder={''}
              value={myDetails?.fullName}
              className={' bg-white w-full  p-3'}
              label={'Full Name'}
            />
          </div>

          <div className=" ">
            <Input
              placeholder={''}
              className={' bg-white w-full  p-3'}
              label={'Enter CNIC No'}
              value={myDetails?.cnic}
            />
          </div>
          <div className="  pt-2">
            {/* <Input placeholder={''}  className={' bg-white w-full  p-3'} label={'CNIC No Pic'} /> */}
            <label className=" text-black font-medium text-lg">CNIC NO Pic</label>
            <div onClick={() => setIsModalOpen(true)} className="  flex gap-3 pt-2">
              <img src={require('../../../assets/image/ImageSquare.png')} />
              <span className=" text-secondary">cnic.jpg</span>
            </div>
          </div>
        </div>
      </div>
    </Investor_web_portal>
  );
};

export default MyDetails;
